import * as Enum from "src/app/framework/enum.shared";
import * as EnumHK from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-enums";
import * as InterfaceProduct from "src/app/framework/interface.product";
import { Component, Input, ViewChild } from "@angular/core";
import { KeyValue } from "src/app/framework/domain-entity/key-value";
import { common } from "src/app/framework/utils/common";
import { Proposal } from "src/app/models/new-product/base/proposal.model";
import { componentHelper } from "src/app/models/new-product/utils/component.helper";
import { IdentityRolesService } from "src/app/services/identity-roles.service";
import { MasterDataService } from "src/app/services/master-data.service";
import {
  MasterPolicyEntity,
  MasterPolicyService,
} from "src/app/services/master-policy.service";
import { Culture, dateFormatter } from "src/app/shared/date-formatter";
import { Global } from "src/app/shared/global";
import { NotifierService } from "src/app/services/notifier.service";
import { MfHkMessageService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-message.service";
import { ClientService } from "src/app/services/customer/client.service";
import { CoverageTypeService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-coverage.service";
import { QuestionEntity } from "src/app/models/new-product/base/question-entity.model";
import { ClientEntity } from "src/app/models/customer/base/client-entity.model";
import { ProposalEntity } from "src/app/models/new-product/base/proposal-entity.model";
import { ProposalConst } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/mf-sg-proposal-constant";
import { MortgageFireSGTabSchema } from "src/app/models/new-product/sme/mortgage-fire/singapore/base/schemas/mf-sg-tab-schema";
import { cloneDeep } from "lodash";
import { CdkDialogService } from "src/app/services/dialog/cdk-dialog.service";
import { ClientViewComponent } from "src/app/components/shared/partials/mdClient/client-view/client-view.component";
import {
  MdConfirmationMessageV1Component,
  v1DlgInputArgs,
} from "src/app/components/shared/partials/md-confirmation-message-v1/md-confirmation-message-v1.component";
import { MdPreferredDeliveryMethodComponent } from "src/app/components/shared/partials/md-preferred-delivery-method/md-preferred-delivery-method.component";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Options } from "ngx-google-places-autocomplete/objects/options/options";
import { MdInformationMessageComponent } from "src/app/components/shared/partials/md-information-message/md-information-message.component";
import {
  AddEvent,
  CancelEvent,
  EditEvent,
  GridComponent,
  GridDataResult,
  RemoveEvent,
  SaveEvent,
} from "@progress/kendo-angular-grid";
import { dialogActionNotifier } from "src/app/models/new-product/notifier/dialog-action-notifier";
import {
  OccupationSearchComponent,
  occupationSearchDlgInputArgs,
} from "src/app/components/shared/partials/md-occupation/occupation-search/occupation-search.component";
import { mortgageFireSingaporeSettings } from "src/app/models/new-product/sme/mortgage-fire/singapore/const/mf-sg-const";
import { Subject } from "rxjs";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";
import {
  MdConfirmationMessageV5Component,
  v5DlgInputArgs,
} from "src/app/components/shared/partials/md-confirmation-message-v5/md-confirmation-message-v5.component";
import { proposalServiceNotifier } from "src/app/models/new-product/notifier/proposal-service-notifier";
import { clientCreateDlgInputArgs } from "src/app/components/shared/partials/mdClient/client-create/base/base-client-create/client-create-dlg-input-args";

import { MfHkClientCreateComponent } from "../../../md-client/mf-hk-client-create/mf-hk-client-create.component";
import { FireInterestedParties } from "../../../../../singapore/components/master/FireInterestedParties";
import { MfSgEquipmentListingEntity } from "../../../../../singapore/components/master/mf-sg-equipment-listing/MfSgEquipmentListingEntity";
import { MfHkBaseComponent } from "../../../mf-hk-base.component";
import { MortgageFireHongKongProposal } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-proposal";
import { MfHKProposalService } from "src/app/services/new-product/sme/mortgage-fire/hong-kong/mf-hk-proposal.service";
import {
  MfHkClientSearchComponent,
  mfHkClientSearchDlgInputArgs,
} from "../../../md-client/mf-hk-client-search/mf-hk-client-search.component";
import { MortgageFireHongKongFieldValidationManager } from "src/app/models/new-product/sme/mortgage-fire/hong-kong/base/mf-hk-field-validation.manager";
import { ClientAddressEntity } from "src/app/models/customer/base/client-address-entity.model";
import { MfHkAddressHelpFormComponent } from "../../../mf-hk-address-help-form/mf-hk-address-help-form.component";
import { AbstractProductFactory } from "src/app/models/new-product/abstract-factory/factory/abstract-product-factory";
import { ProductService } from "src/app/services/new-product/base/product.service";

@Component({
  selector: "mf-hk-master",
  templateUrl: "./mf-hk-master.component.html",
  styleUrls: ["./mf-hk-master.component.scss"],
})
export class MfHkMasterComponent extends MfHkBaseComponent {
  noAnswerDefaultText = ProposalConst.noAnswerDefaultText;
  @Input() public proposal!: MortgageFireHongKongProposal; //temp using @Input()

  @ViewChild("riskInformation_autocomplete")
  riskInformation_autocomplete!: GooglePlaceDirective;
  @ViewChild("riskCarParkInformation_autocomplete")
  riskCarParkInformation_autocomplete!: GooglePlaceDirective;
  @ViewChild("policyInformation_autocomplete")
  policyInformation_autocomplete!: GooglePlaceDirective;

  proposalEntity!: ProposalEntity;
  public originalInceptionDate: string = "";
  clientDictionary!: ClientEntity;
  private masterPolicyList!: MasterPolicyEntity[];
  private masterPolicyOptions: Array<{ text: string; value: string }> = [];
  currency1HDBDisabled: boolean = true;
  currency1HDBValue!: number;
  productId!: string;
  contactInformation: string = "";
  contactInformationPreferredDelivery: string = "";
  ibgMFDisabled: boolean = true;
  ibgMACDisabled: boolean = true;
  ///Chirag: 28-12-2018 Free Insurance Change For CBG Prodcut
  isFreeInsApplicable: boolean = true;
  NumOfFreeYears: number = 0;
  isEndorsement!: boolean;
  isUnderwriter!: boolean;
  isCancel!: boolean;
  /// DBSEP - 2050 : Automation Of LST
  isAutoLst: boolean = true; /*Need To Set From Auxilary Flag */
  isLstApplicable!: boolean;
  isRenewal!: boolean;
  isIBGProduct!: boolean;
  isCMBProduct!: boolean;
  isWPBProduct!: boolean;
  isNewBusiness!: boolean;
  isGeneralEndorsement!: boolean;
  isChangeOfCorrespondenceAddressType!: boolean;
  isIncomplete!: boolean;
  isRenewalDraft: boolean = false;
  isReferred!: boolean;
  isChangeOfClientInfo!: boolean;
  isSameAsAddressTicked: boolean = false;
  isBuildingSIModified: boolean = false;

  //2022-08-23 dagoh0: APACDM-363 - Plan Type Question Visibility
  isPlanTypeExists: boolean = false;
  hasMSIGPolicy: boolean = false;
  editMSIGPolicy: boolean = false;
  houseHoldContentsAmount?: MasterPolicyEntity;
  enableOccupiedAs: boolean = true;
  // Equipment Data
  equipmentData!: any[];
  equipmentDataLength!: number;
  currentSelectedEquipmentGridOptions: any;
  wnd: any;
  window: any;
  detailsTemplate: any;
  onFirstRun: boolean = true;
  nationalityList!: any[];

  // Interested party
  claimData!: any[];
  claimDataLength!: number;
  currentSelectedInterestedGridOptions: any;

  // Additional Insured Details
  otherInsuredData!: any[];
  otherInsuredDataLength!: number;
  readOnlyAddressField!: any[];
  currentSelectedInsuredGridOptions: any;
  private msg_deleteWarning: string =
    "Are you sure you wish to delete the item?";
  private msg_masterPolicyChangeWarning: string =
    "Please confirm if you wish to change the Master Policy Number." +
    " All the data in the Equipment Listing will be lost. Click OK to proceed, else please click Cancel?";
  countryList!: any[];
  labelText: string = "Interested Parties";
  defaultNOI: string = "";
  editableNOI: boolean = true;
  showFreeInsuranceNoYear: boolean = false;
  interestedPartyGridLength = 30;

  isEditable: boolean = false;
  isNewClient: boolean = true;
  refundDisplayText!: string;
  preferredDeliveryText!: string;
  isMasterStateVisible: boolean = false;
  countryExcludeState: any = ["SG", "HK"];
  category!: string;
  masterPolicyNumber!: string;
  suppressNotification: boolean = false;
  displayManualUnderwriting: boolean = false;

  private msg_manualUnderwritting_warning: string =
    "Are you sure you wish to perform manual underwritting? " +
    "Once checked, there is no option to uncheck. Click OK to proceed, else please click Cancel.";
  protected msg_building: string =
    "On the building including landlord’s fixtures and fitting (excluding drains and foundations)";
  firstOnLoad: boolean = true;

  lstDetailsTemplate: any = [
    {
      rowID: 0,
      account: "8TPQZA",
      reference: "XOL",
      percent1: 0,
      amount1: 0,
      percent2: 0,
      amount2: 0,
    },
    {
      rowID: 1,
      account: "8TPQUJ",
      reference: "80% of LST",
      percent1: 0,
      amount1: 0,
      percent2: 0,
      amount2: 0,
    },
    {
      rowID: 2,
      account: "8TPQUK",
      reference: "15% of LST",
      percent1: 0,
      amount1: 0,
      percent2: 0,
      amount2: 0,
    },
    {
      rowID: 3,
      account: "8TPPPV",
      reference: "5% of LST",
      percent1: 0,
      amount1: 0,
      percent2: 0,
      amount2: 0,
    },
  ];
  lstDefaultValue: any;
  clearEquipmentListing: Subject<boolean> = new Subject();
  equipmentDropdownOptions!: KeyValue<any>[];
  @ViewChild("qs_risk_sameAsAddress_checkbox", { static: false })
  public qs_risk_sameAsAddress_checkbox: any;

  @ViewChild("qs_master_manualUnderwritting", { static: false })
  public qs_master_manualUnderwritting_checkbox: any;

  @ViewChild("qs_master_cancellation_masterpiece_checkbox", { static: false })
  public qs_master_cancellation_masterpiece_checkbox: any;

  @ViewChild("qs_master_waiveMinimumPremium_checkbox", { static: false })
  public qs_master_waiveMinimumPremium_checkbox: any;

  @ViewChild("lst_0_percent1", { static: false })
  public lst_0_percent1: any;

  @ViewChild("lst_0_amount1", { static: false })
  public lst_0_amount1: any;

  @ViewChild("lst_0_amount2", { static: false })
  public lst_0_amount2: any;

  @ViewChild("lst_1_percent1", { static: false })
  public lst_1_percent1: any;

  @ViewChild("lst_1_amount1", { static: false })
  public lst_1_amount1: any;

  @ViewChild("lst_1_percent2", { static: false })
  public lst_1_percent2: any;

  @ViewChild("lst_1_amount2", { static: false })
  public lst_1_amount2: any;

  @ViewChild("lst_2_percent1", { static: false })
  public lst_2_percent1: any;

  @ViewChild("lst_2_amount1", { static: false })
  public lst_2_amount1: any;

  @ViewChild("lst_2_percent2", { static: false })
  public lst_2_percent2: any;

  @ViewChild("lst_2_amount2", { static: false })
  public lst_2_amount2: any;

  @ViewChild("lst_3_percent1", { static: false })
  public lst_3_percent1: any;

  @ViewChild("lst_3_amount1", { static: false })
  public lst_3_amount1: any;

  @ViewChild("lst_0_percent2", { static: false })
  public lst_0_percent2: any;

  @ViewChild("lst_3_amount2", { static: false })
  public lst_3_amount2: any;

  // @ViewChild("riskInformation_autocomplete", { static: false })
  // public riskInformation_autocomplete: any;

  // @ViewChild("policyInformation_autocomplete", { static: false })
  // public policyInformation_autocomplete: any;

  //https://github.com/skynet2/ngx-google-places-autocomplete/issues/91
  googlePlacesOptions = {
    types: ["address"],
    componentRestrictions: { country: ["hk"] },
  } as unknown as Options;

  public sort: SortDescriptor[] = [
    {
      field: "PartyID",
      dir: "desc",
    },
  ];
  hideInterestedPartiesActionCol: boolean = false;
  addButtonVisiiblity: boolean = false;
  labelTextInterestedParty: string = "Add New Interested party";
  private editedRowIndex!: number | undefined;
  public gridFireInterestedParties!: GridDataResult;

  qs_master_effectiveDate_datePicker_minDate?: Date;
  qs_master_effectiveDate_datePicker_maxDate?: Date;

  qs_master_poi_startDate_datePicker_minDate?: Date;
  qs_master_poi_startDate_datePicker_maxDate?: Date;
  hkDistrictList!: KeyValue<string>[];
  selectCoverageType!: string;
  fieldValidationManager!: MortgageFireHongKongFieldValidationManager;

  productFactory!: AbstractProductFactory;

  constructor(
    public identityRolesService: IdentityRolesService,
    private cdkDialogService: CdkDialogService,
    public proposalService: MfHKProposalService,
    private masterPolicyService: MasterPolicyService,
    private masterDataService: MasterDataService,
    private clientService: ClientService,
    private notifier: NotifierService,
    private mfHkMessageService: MfHkMessageService,
    public productService: ProductService,
    public coverageTypeService: CoverageTypeService
  ) {
    super();

    this.tabId = MortgageFireSGTabSchema.master.index.toString();
    // // this.googleSearchAutoCompletePolicyInformation =
    // //     new GoogleAddressAutoCompleteManager('policyInformation_autocomplete', 'sg', this.updateAddressValues);
    // this.googleSearchAutoCompleteRiskInformation =
    //     new GoogleAddressAutoCompleteManager('riskInformation_autocomplete', 'sg', this.updateAddressValues);

    super.subscribeAnswerChangedNotifier(this.answerChanged);

    //reset
    this.gridFireInterestedParties = {
      data: [],
      total: 0,
    };
    // const scope = $scope;
    // $scope.$on('$destroy', () => {

    //     $('#masterTab').empty();
    //     scope.$destroy();
    // });
  }

  compareQuestionAnswer(ques: QuestionEntity) {
    return (
      ques!.answer == 1 || ques!.answer === "True" || ques!.answer === true
    );
  }

  updateModelAnswer(ques: QuestionEntity) {
    if (ques!.answer === "True") {
      ques.answer = false;
    } else if (ques!.answer === "False") {
      ques.answer = true;
    } else {
      ques.answer = !ques.answer;
    }
  }

  canEditProposal() {
    if (common.isDefined(this.proposal!.refreshedProposalEntity)) {
      return (
        !this.proposal!.refreshedProposalEntity.proposalReadOnly &&
        !this.isEndorsement &&
        !this.isCancel
      );
    }
    return true;
  }

  showClientSearch() {
    return this.productFactory
      .master()
      .showClientSearch(this.identityRolesService, this.proposal);
  }

  //#region additional insured
  public addHandler(args: AddEvent): void {
    //formInstance.reset();
    // close the previously edited item
    //this.closeEditor(args.sender);
    // open a new item editor
    args.sender.addRow(new FireInterestedParties("", this.defaultNOI));
  }

  public editHandler(args: EditEvent): void {
    // close the previously edited item
    this.closeEditor(args.sender);
    // edit the row
    args.sender.editRow(args.rowIndex);
  }

  public cancelHandler(args: CancelEvent): void {
    // call the helper method-
    this.closeEditor(args.sender, args.rowIndex);
  }

  private getPartyId(): number {
    let parties: any = this.gridFireInterestedParties!.data!.map((item) => {
      return item.PartyID;
    });
    return Math.max(...parties) + 1;
  }
  public saveHandler(args: SaveEvent): void {
    // update the data source
    if (args.isNew) {
      const oldData: any = args.sender.data;
      let PartyId = oldData.data.length > 0 ? this.getPartyId() : 1;
      let NRIC = args.dataItem.NRIC;
      let NatureOfInterest = args.dataItem.NatureOfInterest;
      let Name = args.dataItem.Name;
      const obj: any = {
        PartyID: PartyId,
        Name: Name,
        NatureOfInterest: NatureOfInterest,
        NRIC: NRIC,
      };
      oldData.data.push(obj);
      this.gridFireInterestedParties = {
        data: orderBy(oldData.data, this.sort),
        total: oldData.data.length,
      };
    }
    this.proposal!.qsMaster.qs_fire_interestedParties!.answer = JSON.stringify(
      this.gridFireInterestedParties.data
    );
    // close the editor, that is, revert the row back into view mode
    args.sender.closeRow(args.rowIndex);
  }

  private confirmationRemoveAdditionalInsured(
    data: any,
    args: RemoveEvent
  ): void {
    // remove the current dataItem from the current data source
    if (data.status === "yes") {
      this.gridFireInterestedParties.data.splice(args.rowIndex, 1);
      this.proposal!.qsMaster.qs_fire_interestedParties!.answer =
        JSON.stringify(this.gridFireInterestedParties.data);
    }
  }
  public removeHandler(args: RemoveEvent): void {
    const dataMsg = {
      message: this.msg_deleteWarning,
    };

    let v1InputData: v1DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: {},
      message: dataMsg.message,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV1Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationRemoveAdditionalInsured(result, args);
      }
    });
  }
  //#endregion

  updateCarAddressValues = (
    place: { formatted_address: string; address_components: string | any[] },
    componentForm: { [x: string]: string | number },
    divId: string
  ): void => {
    this.resetCarAddressForm(false, divId);
    if (
      Global.isDefined(place.formatted_address) &&
      divId === "riskCarParkInformation_autocomplete"
    ) {
      let val1 = place.formatted_address
        .replace(", Hong Kong", "")
        .replace(",", " ");
      this.updateAddressAns(
        this.proposal.qsMaster.qs_risk_carParkAddress1!,
        val1,
        true
      );
    }

    if (Global.isDefined(place.address_components)) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        //if (componentForm[addressType]) {
        //const val = place.address_components[i][componentForm[addressType]];
        if (divId === "riskCarParkInformation_autocomplete") {
          switch (addressType) {
            // case "street_number":
            //   let val1 = place.address_components[i].long_name;
            //   this.updateAddressAns(
            //     this.proposal.qsMaster.qs_risk_carParkAddress1!,
            //     val1,
            //     true
            //   );
            //   break;
            // case "route":
            //   let val2 = place.address_components[i].long_name;
            //   this.updateAddressAns(
            //     this.proposal.qsMaster.qs_risk_carParkAddress2!,
            //     val2,
            //     true
            //   );
            //   break;
            // case "locality":
            //   let val3 = place.address_components[i].long_name;
            //   this.updateAddressAns(
            //     this.proposal.qsMaster.qs_risk_insuredCity!,
            //     val3
            //   );
            //   break;
            // case "postal_code":
            //   let val4 = place.address_components[i].long_name;
            //   this.updateAddressAns(
            //     this.proposal.qsMaster.qs_risk_insuredPostalCode!,
            //     val4,
            //     true
            //   );
            //   break;
            // case "administrative_area_level_1":
            // let val5 = place.address_components[i].short_name;
            // this.updateAddressAns(
            //   this.proposal.qsMaster.qs_risk_carParkAddress3!,
            //   val5,
            //   true
            // );
            // break;
            case "country":
              //short_name is ISO code
              //long_name is 'Hong Kong', which is diff with Country table 'Hong Kong SAR'
              let val6 = place.address_components[i].short_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_risk_carParkCountry!,
                val6
              );
              break;
            default:
              break;
          }
          // clearing the search input
          //this.riskInformation_autocomplete.nativeElement.value = "";//todo
        }
      }
      this.checkIfAddressReadOnlyField();
    }
    //this.$scope.$apply();//todo
  };

  resetCarAddressForm = (fromResetButton: boolean, divId: string) => {
    if (divId === "riskInformation_autocomplete") {
      this.readOnlyAddressField = [
        "qs_risk_insuredAddress1",
        "qs_risk_insuredAddress2",
        "qs_risk_insuredCity",
        "qs_risk_insuredPostalCode",
      ];

      this.proposal.qsMaster.qs_risk_carParkAddress1!.clearAnswer();
      this.proposal.qsMaster.qs_risk_carParkAddress2!.clearAnswer();
      this.proposal.qsMaster.qs_risk_carParkAddress3!.clearAnswer();
      this.proposal.qsMaster.qs_risk_carParkAddress4!.clearAnswer();
      this.proposal.qsMaster.qs_risk_carParkCountry!.clearAnswer();

      this.proposal.qsMaster.qs_risk_insuredAddress1!.readOnly = false;
      this.proposal.qsMaster.qs_risk_insuredAddress2!.readOnly = false;
      this.proposal.qsMaster.qs_risk_insuredDistCode!.readOnly = false;
      this.proposal.qsMaster.qs_risk_insuredPostalCode!.readOnly = false;
      this.proposal.qsMaster.qs_risk_insuredCity!.readOnly = false;
      this.proposal.qsMaster.qs_risk_insuredState!.readOnly = false;

      this.proposal.qsMaster.qs_risk_carParkAddress1!.readOnly = false;
      this.proposal.qsMaster.qs_risk_carParkAddress2!.readOnly = false;
      this.proposal.qsMaster.qs_risk_carParkAddress3!.readOnly = false;
      this.proposal.qsMaster.qs_risk_carParkAddress4!.readOnly = false;
      //this.proposal.qsMaster.qs_risk_carParkCountry!.readOnly = false;
      if (fromResetButton) {
        this.proposal.qsMaster.qs_risk_insuredAddress_building!.clearAnswer();
        this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.clearAnswer();
        // clearing the search input
        // this.riskInformation_autocomplete.nativeElement.value = ""; //todo
      }
    }
  };

  public qs_fire_occupied_onValueChange = (args: any) => {
    /*
      {k:"1" , v:"R - Residential"},
      {k:"2" , v:"I - Industrial"},
      {k:"3" , v:"O - Office"},
      {k:"4" , v:"S - Retail Shop"}, 
      {k:"5" , v:"T - Good Quality Village"}, 
      {k:"6" , v:"U - Selected Village"}, 
      {k:"7" , v:"V - Villa(HASE)"},
      {k:"8" , v:"H - House"}

      Residential - 939917C3-3772-4B73-84BE-AE17C6D39A80/Mortgage Fire Residential
      INDUSTRIAL - EE4E271E-BD14-42E2-A02A-96A4C24C76DD/Mortgage Fire
      Office - EE4E271E-BD14-42E2-A02A-96A4C24C76DD
      Retail Shop - EE4E271E-BD14-42E2-A02A-96A4C24C76DD
      Good QUality village - 939917C3-3772-4B73-84BE-AE17C6D39A80
      selected village - 939917C3-3772-4B73-84BE-AE17C6D39A80
      villa(hase) - 939917C3-3772-4B73-84BE-AE17C6D39A80
      house - 939917C3-3772-4B73-84BE-AE17C6D39A80
    */
    if (this.isWPBProduct) {
      this.setWpb_masterPolicyNumber(args);
      this.set_risk_occupancy(args);
    } else {
      this.setCmb_masterPolicyNumber(args);
      this.set_risk_occupancy(args);
    }
  };

  private setWpb_masterPolicyNumber(args: any) {
    switch (args) {
      case "1":
      case "5":
      case "6":
      case "7":
      case "8":
        this.proposalService.proposal.qsMaster.qs_master_masterPolicyNumber!.answer =
          "939917C3-3772-4B73-84BE-AE17C6D39A80".toLowerCase(); //Mortgage Fire Residential
        break;
      default:
        this.proposalService.proposal.qsMaster.qs_master_masterPolicyNumber!.answer =
          "EE4E271E-BD14-42E2-A02A-96A4C24C76DD".toLowerCase(); //Mortgage Fire
        break;
    }
  }

  private setCmb_masterPolicyNumber(args: any) {
    switch (args) {
      case "1":
      case "5":
      case "6":
      case "7":
      case "8":
        this.proposalService.proposal.qsMaster.qs_master_masterPolicyNumber!.answer =
          "699E97F7-F392-4C48-BB8D-DD0A73A17E71".toLowerCase(); //Mortgage Fire Residential
        break;
      default:
        this.proposalService.proposal.qsMaster.qs_master_masterPolicyNumber!.answer =
          "FF4E271E-BD14-42E2-A02A-96A4C24C76DD".toLowerCase(); //Mortgage Fire
        break;
    }
  }

  private set_risk_occupancy(args: any) {
    switch (args) {
      case "1":
        this.proposalService.proposal.qsMaster.qs_risk_occupancy!.answer =
          "Residential";
        break;
      case "2":
        this.proposalService.proposal.qsMaster.qs_risk_occupancy!.answer =
          "Industrial";
        break;
      case "3":
        this.proposalService.proposal.qsMaster.qs_risk_occupancy!.answer =
          "Office";
        break;
      case "4":
        this.proposalService.proposal.qsMaster.qs_risk_occupancy!.answer =
          "Retail Shop";
        break;
      case "5":
        this.proposalService.proposal.qsMaster.qs_risk_occupancy!.answer =
          "Good Quality Village";
        break;
      case "6":
        this.proposalService.proposal.qsMaster.qs_risk_occupancy!.answer =
          "Selected Village";
        break;
      case "7":
        this.proposalService.proposal.qsMaster.qs_risk_occupancy!.answer =
          "Villa(HASE)";
        break;
      case "8":
        this.proposalService.proposal.qsMaster.qs_risk_occupancy!.answer =
          "House";
        break;
      default:
        break;
    }
  }

  //#region migrate from old angularjs
  private answerChanged = (kv: KeyValue<QuestionEntity>) => {
    const key = kv.key;
    const question = kv.value;

    switch (key) {
      //// 12/10/2018 : Chirag Added Code For FreeInsurance Start/ End Date
      case this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.key:
        if (this.isIBGProduct) this.setFreeInsuranceDate();
        else if (!this.isIBGProduct && this.proposal.proposalStatus === 6)
          this.setFreeInsuranceDateForCBG();
        break;
      /// 12/10/2018 : Chirag Added Method For FreeInsurance Start/End Date
      case this.proposal.qsMaster.qs_master_poi_startDate!.key:
        this.qs_master_poi_startDate_onChange();
        this.setFreeInsurance_OnPOIDate_Endorsement();
        break;
      case this.proposal.qsMaster.qs_fire_occupied!.key:
        this.qs_fire_occupied_onChange(
          this.proposal.qsMaster.qs_fire_occupied!.answer
        );
        break;
      case this.proposal.qsMaster.qs_fire_replacement!.key:
        this.qs_fire_FSVSumInsured(
          this.proposal.qsMaster.qs_fire_replacement!.answer
        );
        break;
      case this.proposal.qsMaster.qs_master_insuredAddress_building!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredAddress_building!,
          this.proposal.qsMaster.qs_risk_insuredAddress_building!
        );
        break;
      case this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredAddress_lotunit!,
          this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!
        );
        break;
      case this.proposal.qsMaster.qs_master_insuredAddress1!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredAddress1!,
          this.proposal.qsMaster.qs_risk_insuredAddress1!
        );
        break;
      case this.proposal.qsMaster.qs_master_insuredAddress2!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredAddress2!,
          this.proposal.qsMaster.qs_risk_insuredAddress2!
        );
        break;
      case this.proposal.qsMaster.qs_master_insuredPostalCode!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredPostalCode!,
          this.proposal.qsMaster.qs_risk_insuredPostalCode!
        );
        break;
      case this.proposal.qsMaster.qs_master_insuredCity!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredCity!,
          this.proposal.qsMaster.qs_risk_insuredCity!
        );
        break;
      case this.proposal.qsMaster.qs_master_insuredState!.key:
        this.copyMasterAddress_onChange(
          this.proposal.qsMaster.qs_master_insuredState!,
          this.proposal.qsMaster.qs_risk_insuredState!
        );
        break;
      case this.proposal.qsMaster.qs_master_masterPolicyNumber!.key:
        if (!this.suppressNotification) {
          this.qs_master_masterPolicyNumber_onChange(
            this.proposal.qsMaster.qs_master_masterPolicyNumber!.answer
          );
        }
        break;
      case this.proposal.qsMaster.qs_master_occupancyRate!.key:
      case this.proposal.qsMaster.qs_machinery_equipment!.key:
        // reset the aux if underwriter changes it
        // this.resetAuxiliaryModel();
        break;
      case this.proposal.qsMaster.qs_risk_sameAsAddress!.key:
        this.qs_risk_sameAsAddress_onChange(
          this.proposal.qsMaster.qs_risk_sameAsAddress!.answer
        );
        break;
      case this.proposal.qsMaster.qs_fire_building!.key:
        this.qs_master_qs_fire_building(
          this.proposal.qsMaster.qs_fire_building!.answer
        );
        break;
      case this.proposal.qsMaster.qs_master_effectiveDate!.key:
        this.qs_master_effectiveDates_onChange(
          this.proposal.qsMaster.qs_master_effectiveDate!.answer
        );
        break;

      case this.proposal.qsMaster.qs_fire_freeInsuranceFirstYear!.key:
        this.qs_fire_freeInsuranceFirstYear_onChange(
          this.proposal.qsMaster.qs_fire_freeInsuranceFirstYear!.answer
        );
        break;
      case this.proposal.qsMaster.qs_master_lst!.key:
        this.qs_master_lst_onChange(
          this.proposal.qsMaster.qs_master_lst!.answer
        );
        break;
      case this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.key:
        this.qs_master_preferredDeliveryMethodChange(
          this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer
        );
        break;
      //2022-08-24 dagoh0: APACDM-363 - trigger action when plan type is changed
      case this.proposal.qsMaster.qs_master_planType!.key:
        this.qs_master_planType_onChange(
          this.proposal.qsMaster.qs_master_planType!.answer
        );
        break;
      default:
        break;
    }
  };

  override ngOnInit(): void {
    this.initialize();
    componentHelper.notifyPageLoaded("mf-hk-master.component", this.proposal);

    this.masterDataService.getCountryList().subscribe((response: any[]) => {
      this.countryList = response;
    });
    this.masterDataService
      .getHkDistrictCodes()
      .subscribe((responseHkDistrictCodes: KeyValue<string>[]) => {
        this.hkDistrictList = responseHkDistrictCodes;
        const options = new Array<KeyValue<string>>();
        if (common.isDefined(responseHkDistrictCodes)) {
          responseHkDistrictCodes.forEach((mp) => {
            options.push(new KeyValue<string>(mp.key, mp.value));
          });
        }
        this.proposal.qsMaster.qs_risk_insuredDistCode!.options = options;
      });
    // fetchMasterPolicyList is being call to for the equipment type listing usage
    this.fetchMasterPolicyList();

    super.subscribeProposalLoadedNotifier(this.proposalSaved);
    super.subscribeProposalSavedNotifier(this.proposalSaved);
  }

  private getMasterPolicyDetails = (): MasterPolicyEntity => {
    const masterPolicyNumber =
      this.proposal.qsMaster.qs_master_masterPolicyNumber!.readAnswerAsString();
    let found: boolean = false;
    let masterPolicy = <MasterPolicyEntity>{};
    this.masterPolicyList.every((mp) => {
      if (mp.id === masterPolicyNumber) {
        found = true;
        masterPolicy = mp;
        return false;
      }
      return true;
    });
    return masterPolicy;
  };

  fetchMasterPolicyList = () => {
    this.masterPolicyService
      .getList(this.identityRolesService.getIdentity().currentUser())
      .subscribe((response) => {
        // update list
        this.masterPolicyList = response;

        this.masterPolicyOptions = new Array<{ text: string; value: string }>();

        this.masterPolicyList.forEach((mp) => {
          const displayText =
            mp.policyType +
            " - " +
            mp.description +
            " - " +
            mp.meridianMasterPolicyNumber;
          this.masterPolicyOptions.push({ text: displayText, value: mp.id });
        });
      });
  };

  updateNativeElement(ele: any, value: boolean) {
    if (ele !== undefined) {
      ele.nativeElement.checked = value;
    }
    this.firstOnLoad = false;
  }

  hide_qs_master_refund_onAllNonFinEn() {
    if (
      this.proposal.transType === Enum.TransType.END &&
      //this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete && //DBSEP-6303
      (this.proposal.endorsementType === Enum.EndorsementType.LoanRedemption ||
        this.proposal.endorsementType ===
          Enum.EndorsementType.GeneralEndorsement ||
        this.proposal.endorsementType ===
          Enum.EndorsementType.ChangeOfClientInfo)
    ) {
      return true;
    }
    return false;
  }

  private setProduct() {
    this.isCMBProduct =
      this.proposal.productType === EnumHK.MortgageFireHKProduct.HSCMB
        ? true
        : false;
    this.isWPBProduct =
      this.proposal.productType === EnumHK.MortgageFireHKProduct.HSWPB
        ? true
        : false;

    if (!this.productFactory) {
      this.productFactory = this.productService.getHKProductFactory(
        this.proposal.productType
      );
    }
  }

  private proposalSaved = (event: KeyValue<Proposal>) => {
    if (!this.isIBGProduct) {
      this.getContactInformation();
    }
    if (
      this.isIBGProduct &&
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!
        .answer == null
    ) {
      this.updateContactInformation();
    }
    let checkCorrespondingAddressCbg =
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.isAnswered() &&
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.answer.includes(
        "|"
      );
    if (checkCorrespondingAddressCbg) {
      this.correspondanceDeliveryAddress(null);
    }
    this.setProduct(); //has to be done before getDisplayManualUnderwriting()
    this.getDisplayManualUnderwriting();
    this.proposal = event.value as MortgageFireHongKongProposal;

    this.productFactory = this.productService.getHKProductFactory(
      this.proposal.productType
    );
    // validate business rules after proposal is saved successfully
    this.proposalService.businessRulesManager.validate();

    this.qs_risk_sameAsAddress_onChange(
      this.proposal.qsMaster.qs_risk_sameAsAddress!.answer
    );
    if (
      common.isDefined(this.proposal.originalInceptionDate) &&
      this.proposal.originalInceptionDate !== null
    ) {
      this.originalInceptionDate = dateFormatter.format(
        new Date(this.proposal.originalInceptionDate),
        Culture.enSg
      );
    } else {
      this.originalInceptionDate = dateFormatter.format(
        new Date(this.proposal.qsMaster.qs_master_poi_startDate!.answer),
        Culture.enSg
      );
      this.proposal.originalInceptionDate =
        this.proposal.qsMaster.qs_master_poi_startDate!.answer;
    }
    this.isIncomplete =
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
        ? true
        : false;
    this.isReferred =
      this.proposal.proposalStatus === Enum.ProposalStatus.Referred
        ? true
        : false;
    this.isNewBusiness = this.proposal.transType === Enum.TransType.NEW;
    this.isEndorsement = this.proposal.transType === Enum.TransType.END;
    this.isRenewal = this.proposal.transType === Enum.TransType.REN;
    this.isUnderwriter = this.proposal.isUnderwriter;
    this.isCancel = this.proposal.transType === Enum.TransType.CAN;

    if (this.isCMBProduct) {
      //Assign the value to selectCoverageType so it can be use to do comparison during onChange
      this.selectCoverageType =
        this.proposal.qsMaster.qs_fire_coverageValueType!.answer;
      this.msg_building =
        "On the building including landlord’s fixtures and fitting (excluding drains and foundations)";
    } else if (this.isWPBProduct) {
      this.msg_building = "Building";
    }

    this.isIBGProduct = false;
    this.isGeneralEndorsement =
      this.proposal.transType === Enum.TransType.END &&
      this.proposal.endorsementType === Enum.EndorsementType.GeneralEndorsement;
    // New question when copy should refer to default value
    const refreshQuestion = this.proposal.refreshedProposalEntity.questions;
    // tslint:disable-next-line:no-unused-expression

    this.isRenewalDraft =
      (this.isIncomplete && this.isRenewal) || this.isReferred;
    // Allow To Edit MSIG policy for IBG prodcut
    const replacingPolicyNo =
      this.proposal.qsMaster.qs_master_replacePolicyNo!.readAnswerAsString();
    this.hasMSIGPolicy =
      common.isDefined(replacingPolicyNo) && replacingPolicyNo!.trim() !== "";
    this.editMSIGPolicy =
      this.hasMSIGPolicy &&
      this.isIncomplete &&
      this.proposal.endorsementType === Enum.EndorsementType.GeneralEndorsement;
    if (this.hasMSIGPolicy) {
      this.proposal.qsMaster.qs_master_replacePolicyNo!.displayText =
        "Original Policy Number";
    } else {
      this.proposal.qsMaster.qs_master_replacePolicyNo!.displayText =
        "Replacing Policy No";
    }
    if (
      this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === "True" ||
      this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === true
    ) {
      this.updateNativeElement(
        this.qs_master_manualUnderwritting_checkbox,
        true
      );
      this.proposal.qsMaster.qs_master_manualUnderwritting!.isDisabled = true;
      this.proposal.qsMaster.qs_master_manualUnderwritting!.answer = true;
      this.manualUnderwritting();
    } else {
      this.updateNativeElement(
        this.qs_master_manualUnderwritting_checkbox,
        false
      );
      this.proposal.qsMaster.qs_master_manualUnderwritting!!.isDisabled = false;
      switch (this.proposal.productType) {
        case EnumHK.MortgageFireHKProduct.HSCMB:
        case EnumHK.MortgageFireHKProduct.HSWPB:
          this.currency1HDBDisabled = true;
          break;
      }
    }
    if (
      this.proposal.qsMaster.qs_master_cancellation_masterpiece!.answer ===
        "True" ||
      this.proposal.qsMaster.qs_master_cancellation_masterpiece!.answer === true
    ) {
      if (common.isDefined(this.qs_master_cancellation_masterpiece_checkbox)) {
        this.qs_master_cancellation_masterpiece_checkbox.nativeElement.checked =
          true;
      }
      this.proposal.qsMaster.qs_master_cancellation_masterpiece!.answer = true;
    } else {
      if (common.isDefined(this.qs_master_cancellation_masterpiece_checkbox)) {
        this.qs_master_cancellation_masterpiece_checkbox.nativeElement.checked =
          false;
      }
      this.proposal.qsMaster.qs_master_cancellation_masterpiece!.isDisabled =
        false;
    }
    switch (this.proposal.productType) {
      case EnumHK.MortgageFireHKProduct.HSCMB:
      case EnumHK.MortgageFireHKProduct.HSWPB:
        this.isChangeOfCorrespondenceAddressType =
          this.proposal.endorsementType ===
            Enum.EndorsementType.ChangeOfCorrespondenceAddress &&
          (this.isIncomplete ||
            (this.isReferred &&
              this.proposalService.proposal.isUnderwriter &&
              !this.identityRolesService.isOperationUnderwriter &&
              !this.identityRolesService.isClaimant &&
              !this.identityRolesService.isProductReadOnly));
        this.isChangeOfClientInfo =
          this.proposal.endorsementType ===
            Enum.EndorsementType.ChangeOfClientInfo &&
          (this.isIncomplete ||
            (this.isReferred &&
              this.proposalService.proposal.isUnderwriter &&
              !this.identityRolesService.isOperationUnderwriter &&
              !this.identityRolesService.isClaimant &&
              !this.identityRolesService.isProductReadOnly) ||
            (this.isIncomplete && this.identityRolesService.IsCSCBGSG));
        //2022-08-23 dagoh0: APACDM-363 - determine is this a change of plan type endorsement
        //and enabled the change of plan type
        this.isPlanTypeExists =
          this.proposal.qsMaster.qs_master_planType != null &&
          this.proposal.qsMaster.qs_master_planType.answer != null &&
          (this.identityRolesService.IsCbsOps ||
            this.identityRolesService.isUnderwriter ||
            this.identityRolesService.isOperationUnderwriter);
        break;
      // case EnumSG.MortgageFireSgProduct.IBGMAC:
      // case EnumSG.MortgageFireSgProduct.IBGMF:
      //   this.isChangeOfCorrespondenceAddressType =
      //     ((this.isEndorsement &&
      //       this.proposal.endorsementType ===
      //         Enum.EndorsementType.GeneralEndorsement) ||
      //       this.isNewBusiness) &&
      //     ((this.isIncomplete && this.proposal.isUnderwriter) ||
      //       (!(this.isReferred && this.proposal.isUnderwriter) &&
      //         !this.identityRolesService.isClaimant &&
      //         !this.identityRolesService.isProductReadOnly));
      //   // Enable client endorsement of IBG product FOR CBG OPS users
      //   if (
      //     this.proposal.transType === Enum.TransType.END &&
      //     this.proposal.endorsementType ===
      //       Enum.EndorsementType.GeneralEndorsement &&
      //     this.isEndorsement &&
      //     this.isIncomplete &&
      //     this.identityRolesService.IsCbsOps
      //   ) {
      //     this.isChangeOfCorrespondenceAddressType = true;
      //   }
      //   if (
      //     this.proposal.transType === Enum.TransType.CAN &&
      //     this.proposal.endorsementType ===
      //       Enum.EndorsementType.ChangeOfCorrespondenceAddress &&
      //     this.isIncomplete &&
      //     this.identityRolesService.isOperationUnderwriter
      //   ) {
      //     this.isChangeOfCorrespondenceAddressType = true;
      //   }
      //   if (this.proposal.proposalStatus === Enum.ProposalStatus.Accepted) {
      //     this.isChangeOfCorrespondenceAddressType = false;
      //   }
      //   break;
      default:
      // do nothing
    }
    if (!this.isIBGProduct) {
      this.labelText = "Additional Insured";
      if (this.proposal.qsMaster.qs_master_financialBranch!.isAnswered()) {
        this.proposal.qsMaster.qs_master_financialBranch!.answer =
          this.proposal.qsMaster.qs_master_financialBranch!.answer;
      } else {
        this.proposal.qsMaster.qs_master_financialBranch!.answer =
          "3cd1ab19-82af-41a5-9608-a1c2bcff2f70";
      }
      this.proposal.qsMaster.qs_master_financialBranch!.displayText =
        "Financial Institution Branch / BU PC Code";
      this.defaultNOI = "Additional Insured";
      this.editableNOI = false;
    }

    if (this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer > 0) {
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer =
        this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer;
    } else {
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer = 0;
    }
    if (
      (this.proposal.proposalStatus === Enum.ProposalStatus.Referred || //HE-229
        this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete) &&
      (this.proposal.transType === Enum.TransType.NEW ||
        //HE-1058 add edit for Renewal
        this.proposal.transType === Enum.TransType.REN) &&
      this.proposal.customerMappingId! > 0 &&
      (this.identityRolesService.isMaker ||
        this.identityRolesService.isBroker || //DBSEP-7122
        this.identityRolesService.isUnderwriter)
    ) {
      this.isEditable = true;
    } else {
      this.isEditable = false;
    }
    if (this.isEndorsement || this.isCancel) {
      // set display text for refund
      this.qs_master_refundDisplayText(
        this.proposal.qsMaster.qs_master_refund!
      );
    }

    //no matter going to load-redeem, or already loan-redeemed, qs_otherinfo_MortgLoanAcctNum1 no longer requires
    if (
      this.isIncomplete &&
      this.proposal.endorsementType === Enum.EndorsementType.LoanRedemption
    ) {
      this.proposal.qsMaster.qs_otherinfo_MortgLoanAcctNum1!.answer = ""; //DBSEP-5825
      this.proposal.qsMaster.qs_otherinfo_MortgLoanAcctNum1!.required = false;
    }

    if (this.proposal.loanRedeemed) {
      this.proposal.qsMaster.qs_otherinfo_MortgLoanAcctNum1!.required = false;
    }

    if (
      (this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer ==
        null ||
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer ==
          "") &&
      this.proposal.proposalStatus == Enum.ProposalStatus.Incomplete
    )
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer = "5";
    this.qs_master_preferredDeliveryText(
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod!
    );

    //todo: google auto completer search
    const component = this;
    //     const timeout = Application.angular_$timeout();
    setTimeout(() => {
      //         // initializing the google auto completer search
      //         this.googleSearchAutoCompleteRiskInformation.InitializeAddressAutoCompleteElement();
      // Other Insured
      component.otherInsuredData =
        component.proposal.qsMaster.qs_master_additionalInsured!.answer === ""
          ? []
          : component.processTableDataOtherInsured(
              component.proposal.qsMaster.qs_master_additionalInsured!.answer
            );
      component.otherInsuredDataLength = component.otherInsuredData.length;
      // Interested Parties
      component.claimData =
        component.proposal.qsMaster.qs_fire_interestedParties!.answer === ""
          ? []
          : component.processTableDataInteresedParty(
              component.proposal.qsMaster.qs_fire_interestedParties!.answer
            );
      component.claimDataLength = component.claimData.length;
      // Equipment
      component.equipmentData =
        component.proposal.qsMaster.qs_machinery_equipment!.answer === ""
          ? []
          : component.processTableDataEquipment(
              component.proposal.qsMaster.qs_machinery_equipment!.answer
            );
      component.equipmentDataLength = component.equipmentData.length;
      //         const dataSource = new kendo.data.DataSource({
      //             transport: {
      //                 read: component.claimsData_OnRead,
      //                 update: component.claimData_OnUpdate,
      //                 destroy: component.claimData_OnDestroy,
      //                 create: component.claimsData_OnCreate,
      //                 // cache: false,
      //                 parameterMap: (options, operation) => {
      //                     if (operation !== 'read' && options.models) {
      //                         return { models: kendo.stringify(options.models) };
      //                     }
      //                 },
      //             },
      //             batch: true,
      //             pageSize: 5,
      //             schema: {
      //                 model: {
      //                     id: 'PartyID',
      //                     fields: {
      //                         PartyID: { editable: false },
      //                         Name: { validation: { required: true } },
      //                         NatureOfInterest: {
      //                             type: 'text',
      //                             editable: component.editableNOI,
      //                             defaultValue: component.defaultNOI,
      //                             validation: {
      //                                 required: true,
      //                                 NatureOfInterestCheckLength: function (input) {
      //                                     if (input.val() !== '' && input.attr('data-maxlength') > 10) {
      //                                         input.attr('data-NatureOfInterestCheckLength-msg', 'Max Length Reached');
      //                                         return false;
      //                                     }
      //                                     return true;
      //                                 }
      //                             }
      //                         }
      //                     },
      //                 },
      //             },
      //         });
      //         const dataSourceEquipment = new kendo.data.DataSource({
      //             transport: {
      //                 read: component.equipmentData_OnRead,
      //                 update: component.equipmentData_OnUpdate,
      //                 destroy: component.equipmentData_OnDestroy,
      //                 create: component.equipmentData_OnCreate,
      //                 parameterMap: (options, operation) => {
      //                     if (operation !== 'read' && options.models) {
      //                         return { models: kendo.stringify(options.models) };
      //                     }
      //                 },
      //             },
      //             batch: true,
      //             pageSize: 5,
      //             schema: {
      //                 model: {
      //                     id: 'rowID',
      //                     fields: {
      //                         rowID: { editable: false },
      //                         equipmentType: {
      //                             validation: { required: true },
      //                             defaultValue: {
      //                                 key: '',
      //                                 value: ''
      //                             }
      //                         },
      //                         typeOfFinancing: {
      //                             type: 'text',
      //                         },
      //                         equipmentDescription: {
      //                             type: 'text', validation: { required: true }
      //                         },
      //                         sumInsured: {
      //                             type: 'number',
      //                             defaultValue: 0,
      //                             validation: {
      //                                 required: true,
      //                                 sumInsuredCheckLength: function (input) {
      //                                     if (input.attr('data-bind') === 'value:sumInsured') {
      //                                         input.attr('data-sumInsuredCheckLength-msg', 'Sum Insured is required');
      //                                         return (input.val() !== '');
      //                                     }
      //                                     return true;
      //                                 }
      //                             }
      //                         }, additionalInfo: {
      //                             defaultValue: '',
      //                             type: 'text', validation: {
      //                                 required: true,
      //                                 textAreaLengthcheck: function (input) {
      //                                     if (input.attr('data-bind') === 'value:additionalInfo') {
      //                                         input.attr('data-textAreaLengthcheck-msg', 'Addtional Info is required');
      //                                         return (input.val() !== '');
      //                                     }
      //                                     return true;
      //                                 }
      //                             }
      //                         },
      //                     },
      //                 },
      //             },
      //         });
      //         const dataSourceOtherInsured = new kendo.data.DataSource({
      //             transport: {
      //                 read: component.otherInsuredData_OnRead,
      //                 update: component.otherInsuredData_OnUpdate,
      //                 destroy: component.otherInsuredData_OnDestroy,
      //                 create: component.otherInsuredData_OnCreate,
      //                 parameterMap: (options, operation) => {
      //                     if (operation !== 'read' && options.models) {
      //                         return { models: kendo.stringify(options.models) };
      //                     }
      //                 },
      //             },
      //             batch: true,
      //             pageSize: 5,
      //             schema: {
      //                 model: {
      //                     id: 'otherInsuredID',
      //                     fields: {
      //                         otherInsuredID: { editable: false },
      //                         otherInsuredName: { validation: { required: true } },
      //                         cin: { validation: { required: true } },
      //                         otherBRNo: { validation: { required: true } },
      //                     },
      //                 },
      //             },
      //         });
      //         // fixed the typescript declaration bugs
      //         const qs_master_interestedParty_table_grid: any = angular.element('#qs_fire_interestedParties_table_grid');
      //         const showAction: boolean = component.proposal.qsMaster.qs_fire_interestedParties.readOnly ? true : false;
      //         let labelTextInterestedParty: string = 'Add New Interested party';
      //         const hideInterestedPartiesActionCol: boolean = component.proposal.qsMaster.qs_fire_interestedParties.readOnly;
      if (!this.isIBGProduct) {
        this.labelTextInterestedParty = "Add Additional Insured";
      }
      //         // Destroy the kendo Grid if defined and recreate everytime
      //         if (common.isDefined(qs_master_interestedParty_table_grid.data('kendoGrid'))) {
      //             const interestedPartyGrid = qs_master_interestedParty_table_grid.data('kendoGrid');
      //             interestedPartyGrid.destroy();
      //             angular.element('#qs_fire_interestedParties_table_grid').empty();
      //         }
      //         qs_master_interestedParty_table_grid.kendoGrid({
      //             dataSource: dataSource,
      //             pageable: true,
      //             height: 280,
      //             toolbar: [{
      //                 text: labelTextInterestedParty,
      //                 name: 'create'
      //             }],
      //             dataBound: () => {
      //                 const grid1 = angular.element('#qs_fire_interestedParties_table_grid').data('kendoGrid');
      //                 if (common.isDefined(grid1) && !this.proposal.qsMaster.qs_fire_interestedParties.readOnly) {
      //                     if (grid1.dataSource.data().length >= this.interestedPartyGridLength) {
      //                         angular.element('#qs_fire_interestedParties_table_grid .k-grid-add').hide();
      //                     } else {
      //                         angular.element('#qs_fire_interestedParties_table_grid .k-grid-add').show();
      //                     }
      //                 } else {
      //                     angular.element('#qs_fire_interestedParties_table_grid .k-grid-add').hide();
      //                 }
      //                 angular.element('#qs_fire_interestedParties_table_grid .k-grid-myDelete span').addClass('k-icon k-delete');
      //             },
      //             columns: [
      //                 {
      //                     field: 'Name',
      //                     title: 'Name',
      //                     width: '350px',
      //                 },
      //                 {
      //                     field: 'NatureOfInterest',
      //                     title: 'Nature of Interest',
      //                     width: '250px',
      //                 },
      //                 {
      //                     hidden: hideInterestedPartiesActionCol,
      //                     field: 'command',
      //                     command: ['edit', {
      //                         name: 'myDelete',
      //                         text: 'Delete',
      //                         click: function (e) {
      //                             e.preventDefault();
      //                             // assign the selected row
      //                             component.currentSelectedInterestedGridOptions = $(e.currentTarget).closest('tr');
      //                             const data = {
      //                                 message: component.msg_deleteWarning,
      //                                 tableName: 'qs_fire_interestedParties_table_grid'
      //                             };
      //                             component._dialog = Dialog.DialogModal.Instance.modalMedium(
      //                                 'shared/partials/mdConfirmationMessageV1/sharedConfirmationMessageV1.html',
      //                                 'SharedConfirmationMessageV1Controller', data
      //                                 , component.confirmationModalCloseForDeleteAction,
      //                                 undefined, undefined, undefined, false, 'standardModal');
      //                         }
      //                     }],
      //                     title: 'Action',
      //                     width: '200px',
      //                     attributes: { 'class': 'textAlignCenter' },
      //                     headerAttributes: { 'style': 'text-align:center !important' },
      //                 }],
      //             edit: component.onGridEditing,
      //             editable: { mode: 'inline', confirmation: false },
      //         });
      //         component.wnd = $('#equipmentDetails')
      //             .kendoWindow({
      //                 title: 'Equipment Details',
      //                 modal: true,
      //                 visible: false,
      //                 resizable: false,
      //                 width: 550
      //             }).data('kendoWindow');
      //         const equipTemplateHtml = $('#equipmentTemplate').html();
      //         component.detailsTemplate = (Global.isDefined(equipTemplateHtml)) ? kendo.template(equipTemplateHtml) : '';
      //         // if (hideInterestedPartiesActionCol) {
      //         //     $('#qs_fire_interestedParties_table_grid .k-header:contains("Action")').hide();
      //         // } else {
      //         //     $('#qs_fire_interestedParties_table_grid .k-header:contains("Action")').show();
      //         // }
      //         const qs_master_equipment_table_grid: any = angular.element('#qs_machinery_equipment_table_grid');
      //         const hideEquipmentActionCol: boolean = component.proposal.qsMaster.qs_machinery_equipment.readOnly;
      //         if (common.isDefined(qs_master_equipment_table_grid.data('kendoGrid'))) {
      //             const equipmentGrid = qs_master_equipment_table_grid.data('kendoGrid');
      //             equipmentGrid.destroy();
      //             angular.element('#qs_machinery_equipment_table_grid').empty();
      //             angular.element('#qs_machinery_equipment_table_grid').off('click');
      //         }
      //         let toolbar: any = [{
      //             text: 'Add New Equipment',
      //             name: 'create',
      //         }];
      //         if (!component.isEndorsement) {
      //             toolbar = component.proposal.qsMaster.qs_machinery_equipment.readOnly ? '' : toolbar;
      //         } else {
      //             if (this.proposal.refreshedProposalEntity.proposalReadOnly && component.proposal.qsMaster.qs_machinery_equipment.readOnly) {
      //                 toolbar = '';
      //             }
      //         }
      //         const showEquipAction: boolean = component.proposal.qsMaster.qs_machinery_equipment.readOnly ? true : false;
      //         timeout(() => {
      //             if (showEquipAction) {
      //                 $('#qs_machinery_equipment_table_grid .k-grid-edit,#qs_machinery_equipment_table_grid .k-grid-myDelete').hide();
      //             }
      //             if (!this.isIBGProduct) {
      //                 /// 30/09/2019 : INC20585048 DBS EPL - FREE INSURANCE DISPLAY DISCREPANCIES and Occcupation Filter issue
      //                 this.filterOccupied(this.proposal.qsMaster.qs_master_masterPolicyNumber.answer);
      //             }
      //         }, 500);
      //         qs_master_equipment_table_grid.kendoGrid({
      //             dataSource: dataSourceEquipment,
      //             pageable: true,
      //             height: 280,
      //             draggable: false,
      //             toolbar: toolbar,
      //             dataBound: () => {
      //                 const grid1 = angular.element('#qs_machinery_equipment_table_grid').data('kendoGrid');
      //                 if (common.isDefined(grid1) && this.proposal.qsMaster.qs_machinery_equipment.readOnly) {
      //                     angular.element('#qs_machinery_equipment_table_grid .k-grid-add').hide();
      //                 }
      //                 $('#qs_machinery_equipment_table_grid .k-grid-myDelete span').addClass('k-icon k-delete');
      //             },
      //             columns: [
      //                 {
      //                     field: 'equipmentType',
      //                     title: 'Type of Equipment',
      //                     width: '250px',
      //                     editor: component.equipmentTypeSectionDropdown,
      //                     template: '#=equipmentType.key#'
      //                 },
      //                 {
      //                     field: 'typeOfFinancing',
      //                     title: 'Serial Number',
      //                     width: '150px',
      //                 },
      //                 {
      //                     field: 'equipmentDescription',
      //                     title: 'Description',
      //                     width: '250px',
      //                 },
      //                 {
      //                     field: 'sumInsured',
      //                     title: 'Sum Insured',
      //                     width: '140px',
      //                     editor: component.equipmentSumInsuredEditor,
      //                     template: '$ #=sumInsured#'
      //                 },
      //                 {
      //                     field: 'additionalInfo',
      //                     title: 'Additional Info',
      //                     editor: component.equipmentAddtionalInfoTextArea,
      //                     template: '#=additionalInfo#',
      //                     width: '250px',
      //                 },
      //                 {
      //                     // hidden: hideEquipmentActionCol,
      //                     field: 'command',
      //                     command: [
      //                         'edit',
      //                         {
      //                             name: 'myDelete',
      //                             text: 'Delete',
      //                             click: function (e) {
      //                                 e.preventDefault();
      //                                 // assign the selected row
      //                                 component.currentSelectedEquipmentGridOptions = $(e.currentTarget).closest('tr');
      //                                 const data = {
      //                                     message: component.msg_deleteWarning,
      //                                     tableName: 'qs_machinery_equipment_table_grid'
      //                                 };
      //                                 component._dialog = Dialog.DialogModal.Instance.modalMedium(
      //                                     'shared/partials/mdConfirmationMessageV1/sharedConfirmationMessageV1.html',
      //                                     'SharedConfirmationMessageV1Controller', data
      //                                     , component.confirmationModalCloseForDeleteAction,
      //                                     undefined, undefined, undefined, false, 'standardModal');
      //                             }
      //                         },
      //                         {
      //                             text: 'Addt. Info',
      //                             click: component.equipListShowDetails,
      //                             name: 'add-info'
      //                         }],
      //                     title: 'Action',
      //                     width: '300px',
      //                     attributes: { 'class': 'textAlignCenter' },
      //                     headerAttributes: { 'style': 'text-align:center !important' },
      //                 }],
      //             edit: component.onGridEditing,
      //             selectable: true,
      //             editable: {
      //                 mode: 'popup',
      //                 confirmation: false,
      //                 draggable: false,
      //                 window: {
      //                     // title: "My Custom Title",
      //                     // animation: false,
      //                     width: '700px',
      //                     height: '500px',
      //                 }
      //             },
      //         });
      //         angular.element('#qs_machinery_equipment_table_grid').on('click', '.k-grid-add', (e) => {
      //             e.preventDefault();
      //             if (!this.proposal.qsMaster.qs_master_masterPolicyNumber.isAnswered()) {
      //                 if (common.isDefined($('#qs_machinery_equipment_table_grid').data('kendoGrid'))) {
      //                     const equipmentGrid = angular.element('#qs_machinery_equipment_table_grid').data('kendoGrid');
      //                     equipmentGrid.removeRow($(e.currentTarget).closest('tr'));
      //                 }
      //                 const notification = {
      //                     message: 'Please choose a master Policy to add Equipment.'
      //                 };
      //                 this._dialog = Dialog.DialogModal.Instance.modalMedium(
      //                     'shared/partials/mdInformationMessage/informationMessageController.html',
      //                     'InformationMessageController', notification
      //                     , '', undefined, undefined, undefined, false, 'standardModal');
      //             } else {
      //                 component.fetchEquipmentDropdownList();
      //             }
      //         });
      //         angular.element('#qs_machinery_equipment_table_grid').on('click', '.k-grid-edit', (e) => {
      //             timeout(() => {
      //                 component.setEquipmentDropdownList();
      //             }, 10);
      //         });
      //         // if (hideEquipmentActionCol) {
      //         //     $('#qs_machinery_equipment_table_grid .k-header:contains("Action")').hide();
      //         // } else {
      //         //     $('#qs_machinery_equipment_table_grid .k-header:contains("Action")').show();
      //         // }
      //         const qs_master_otherInsured_table_grid: any = angular.element('#qs_master_otherInsured_table_grid');
      //         const hideOtherInsuredNameActionCol: boolean = component.proposal.qsMaster.qs_master_otherInsuredName.readOnly;
      //         if (common.isDefined(qs_master_otherInsured_table_grid.data('kendoGrid'))) {
      //             const otherInsuredGrid = qs_master_otherInsured_table_grid.data('kendoGrid');
      //             otherInsuredGrid.destroy();
      //             angular.element('#qs_master_otherInsured_table_grid').empty();
      //         }
      //         qs_master_otherInsured_table_grid.kendoGrid({
      //             dataSource: dataSourceOtherInsured,
      //             pageable: true,
      //             height: 280,
      //             toolbar: [{
      //                 text: 'Add Additional Insured',
      //                 name: 'create'
      //             }],
      //             dataBound: () => {
      //                 const grid1 = angular.element('#qs_master_otherInsured_table_grid').data('kendoGrid');
      //                 if (common.isDefined(grid1) && !this.proposal.qsMaster.qs_master_otherInsuredName.readOnly) {
      //                     if (grid1.dataSource.data().length >= this.interestedPartyGridLength) {
      //                         angular.element('#qs_master_otherInsured_table_grid .k-grid-add').hide();
      //                     } else {
      //                         angular.element('#qs_master_otherInsured_table_grid .k-grid-add').show();
      //                     }
      //                 } else {
      //                     angular.element('#qs_master_otherInsured_table_grid .k-grid-add').hide();
      //                 }
      //                 $('#qs_master_otherInsured_table_grid .k-grid-myDelete span').addClass('k-icon k-delete');
      //             },
      //             columns: [
      //                 {
      //                     field: 'otherInsuredName',
      //                     title: 'Insured Name',
      //                     width: '350px',
      //                 },
      //                 {
      //                     field: 'cin',
      //                     title: 'CIN',
      //                     width: '325px',
      //                 },
      //                 {
      //                     field: 'otherBRNo',
      //                     title: 'Business Registration Number',
      //                     width: '275px',
      //                 },
      //                 {
      //                     hidden: hideOtherInsuredNameActionCol,
      //                     field: 'command',
      //                     command: ['edit', {
      //                         name: 'myDelete',
      //                         text: 'Delete',
      //                         click: function (e) {
      //                             e.preventDefault();
      //                             // assign the selected row
      //                             component.currentSelectedInsuredGridOptions = $(e.currentTarget).closest('tr');
      //                             const data = {
      //                                 message: component.msg_deleteWarning,
      //                                 tableName: 'qs_master_otherInsured_table_grid'
      //                             };
      //                             component._dialog = Dialog.DialogModal.Instance.modalMedium(
      //                                 'shared/partials/mdConfirmationMessageV1/sharedConfirmationMessageV1.html',
      //                                 'SharedConfirmationMessageV1Controller', data
      //                                 , component.confirmationModalCloseForDeleteAction,
      //                                 undefined, undefined, undefined, false, 'standardModal');
      //                         }
      //                     }],
      //                     title: 'Action',
      //                     width: '200px',
      //                     attributes: { 'class': 'textAlignCenter' },
      //                     headerAttributes: { 'style': 'text-align:center !important' },
      //                 }],
      //             edit: component.onGridEditing,
      //             editable: { mode: 'inline', confirmation: false },
      //         });
      /*2022-08-30 dagoh0: APACDM-363 - Consider Plan Type if answer is not null*/
      let annualExpiryDate = cloneDeep(
        component.proposal.qsMaster.qs_master_poi_startDate!.answer
      );
      let minInceptionDate = new Date();
      const planTypeYear =
        this.proposal.qsMaster.qs_master_planType != null &&
        this.proposal.qsMaster.qs_master_planType.answer != null
          ? +this.proposal.qsMaster.qs_master_planType.answer
          : 0;
      if (planTypeYear > 0) {
        annualExpiryDate.setYear(annualExpiryDate.getFullYear() + planTypeYear);
        annualExpiryDate.setDate(annualExpiryDate.getDate() - 1);
      } else {
        annualExpiryDate.setYear(annualExpiryDate.getFullYear() + 1);
        annualExpiryDate.setDate(annualExpiryDate.getDate() - 1);
      }
      minInceptionDate.setDate(minInceptionDate.getDate() - 180); //Backdating rule: Possible up to 180 days
      if (component.proposal.isBroker) {
        const timeDiff = Math.abs(
          new Date().getTime() -
            this.proposal.qsMaster.qs_master_poi_startDate!.answer.getTime()
        );
        const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
        this.qs_master_poi_startDate_datePicker_minDate = minInceptionDate;
        this.qs_master_poi_startDate_datePicker_maxDate = annualExpiryDate;
      } else {
        this.qs_master_poi_startDate_datePicker_minDate = new Date(1900, 1, 1);
        this.qs_master_poi_startDate_datePicker_maxDate = new Date(
          2999,
          12,
          31
        );
      }
      component.proposal.qsMaster.qs_master_poi_endDate!.answer =
        annualExpiryDate;
      component.qs_master_effectiveDate_onChange();
      if (
        (component.isEndorsement &&
          component.proposal.endorsementType !==
            Enum.EndorsementType.ChangeOfPOI) ||
        component.isCancel
      ) {
        component.proposal.qsMaster.qs_master_poi_startDate!.readOnly = true;
      }

      if (this.isIBGProduct) {
        setTimeout(() => {
          // empty country value to search for all
          // this.googleSearchAutoCompletePolicyInformation =
          //     new GoogleAddressAutoCompleteManager('policyInformation_autocomplete', '', this.updateAddressValues);
          // this.googleSearchAutoCompletePolicyInformation.InitializeAddressAutoCompleteElement();
          this.googlePlacesOptions = {
            types: ["address"],
            componentRestrictions: { country: [] }, //no country restriction
          } as unknown as Options;

          if (this.proposal.isBroker) {
            this.proposal.qsMaster.qs_master_insuredNo!.readOnly = true;
            this.proposal.qsMaster.qs_risk_insuredAddress_building!.readOnly =
              true;
            this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.readOnly =
              true;
            if (
              this.identityRolesService.IsCbsOps &&
              this.proposal.transType === Enum.TransType.END &&
              this.proposal.endorsementType ===
                Enum.EndorsementType.GeneralEndorsement &&
              this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
            ) {
              this.proposal.qsMaster.qs_master_insuredNo!.readOnly = false;
            }
          }
          this.qs_master_lst_onChange(
            this.proposal.qsMaster.qs_master_lst!.answer
          );
        }, 200);
      }
      if (
        this.proposal.qsMaster.qs_master_waiveMinimumPremium!.answer === "True"
      ) {
        if (common.isDefined(this.qs_master_waiveMinimumPremium_checkbox)) {
          this.qs_master_waiveMinimumPremium_checkbox.nativeElement.checked =
            true;
        }
      }
    }, 200);

    this.bindGrid(this.proposal.qsMaster.qs_fire_interestedParties!);
  };

  private bindGrid(qs_fire_interestedParties: QuestionEntity) {
    let isIBGProduct = false;
    // this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMF ||
    // this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMAC
    //   ? true
    //   : false;
    if (!isIBGProduct) {
      this.labelTextInterestedParty = "Add Additional Insured";
    }

    this.hideInterestedPartiesActionCol =
      this.proposal!.qsMaster.qs_fire_interestedParties!.readOnly!;

    if (qs_fire_interestedParties.answer) {
      const obj: FireInterestedParties[] = JSON.parse(
        qs_fire_interestedParties.answer
      );
      this.gridFireInterestedParties = {
        data: obj,
        total: obj.length,
      };
    }

    if (
      qs_fire_interestedParties.readOnly !== undefined &&
      !qs_fire_interestedParties.readOnly
    ) {
      if (
        this.gridFireInterestedParties?.total >= this.interestedPartyGridLength
      ) {
        //angular.element('#qs_fire_interestedParties_table_grid .k-grid-add').hide();
        this.addButtonVisiiblity = false;
      } else {
        //angular.element('#qs_fire_interestedParties_table_grid .k-grid-add').show();
        this.addButtonVisiiblity = true;
      }
    } else {
      //angular.element('#qs_fire_interestedParties_table_grid .k-grid-add').hide();
      this.addButtonVisiiblity = false;
    }
    //angular.element('#qs_fire_interestedParties_table_grid .k-grid-myDelete span').addClass('k-icon k-delete');//todo
  }

  // private onGridEditing(arg) {
  //     // setting max length fort each input inside kendo grid due to proposal Data Extractor
  //     // Interested Parties
  //     arg.container.find('input[name="NatureOfInterest"]').attr('maxlength', '50');

  //     arg.container.find('input[name="Name"]').attr('maxlength', '80');

  //     // Equipment Type
  //     arg.container.find('input[name="equipmentDescription"]').attr('maxlength', '50');
  //     arg.container.find('input[name="typeOfFinancing"]').attr('maxlength', '50');
  //     arg.container.find('input[name="NatureOfInterest"]').attr('maxlength', '50');
  //     arg.container.find('input[name="sumInsured"]').attr('maxlength', '10');

  //     // styling
  //     arg.container.find('span[aria-owns="equipmentDropdownList_listbox"]').css('width', '350px');
  //     arg.container.find('input[name="typeOfFinancing"]').css('width', '350px');
  //     arg.container.find('input[name="equipmentDescription"]').css('width', '350px');

  //     // Other Insured details
  //     arg.container.find('input[name="otherInsuredName"]').attr('maxlength', '50');
  //     arg.container.find('input[name="cin"]').attr('maxlength', '50');
  //     arg.container.find('input[name="otherBRNo"]').attr('maxlength', '50');
  //     // for popup window
  //     angular.element('.k-edit-form-container').attr('style', 'width:auto');
  // }

  public refreshEchoPanel = (args: any) => {
    proposalServiceNotifier.saved_notifier$.next(
      new KeyValue(this.proposal.proposalId!.toString(), this.proposal)
    );
  };
  private closeEditor(
    grid: GridComponent,
    rowIndex = this.editedRowIndex
  ): void {
    // close the editor
    grid.closeRow(rowIndex);
    // revert the data item to original state
  }

  private qs_master_effectiveDates_onChange = (data: any) => {
    ///this.proposalService.businessRulesManager.validateCancellationBackdating();//HE-257, Scenario 2
    this.proposalService.businessRulesManager.validateLoanRedemptionBackdating();
  };

  private qs_master_effectiveDate_onChange = (): void => {
    /** Endorsement Restrictions for Effective Date
     * -------------------------------------------------------------------
     *
     * 1. General Endorsement
     * 2. Change Of Correspondence Address
     * 3. Change of Sum Insured
     *    Uw and Broker :
     *   -------------------------
     *   Min : Previous Endorsement Effective Date
     *   Max: Policy End date
     * -----------------------------------------
     * 4. Loan Redemption:
     *    Min : Policy Start Date
     *    Max: Policy End Date
     * --------------------------------------------
     * 5. Change Of POI
     *    UW:
     *    ----------
     *    Min : No Restriction
     *    Max : No Restriction
     *    Broker:
     *    ---------
     *    Min : 1 month from Policy Start Date
     *    Max : No Restriction
     * -----------------------------------------
     * 6. Change Of Sum Insured:
     *    Min : Policy Start Date
     *    Max: Policy End Date
     * --------------------------------------------
     */
    let minEffectiveDate =
      common.isDefined(this.proposal.minimumEffectiveDate) &&
      this.proposal.minimumEffectiveDate !== null
        ? new Date(this.proposal.minimumEffectiveDate)
        : new Date(this.proposal.qsMaster.qs_master_poi_startDate!.answer);
    if (
      this.proposal.minimumEffectiveDate < this.proposal.originalInceptionDate
    ) {
      minEffectiveDate = this.proposal.originalInceptionDate;
    }
    const policyStartDate = new Date(
      this.proposal.qsMaster.qs_master_poi_startDate!.readAnswerAsString()!
    );
    const currentPOI = new Date(new Date().setHours(0, 0, 0, 0));
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));
    const timeDiff = Math.abs(
      policyStartDate.getTime() - currentDate.getTime()
    );
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    let minEffectiveDateLoanRedemption = policyStartDate;
    if (
      policyStartDate < currentDate &&
      diffDays > EnumHK.BackdatedRules.MaximumAllowedLoanRedemption
    ) {
      minEffectiveDateLoanRedemption = minEffectiveDate;
    }

    if (this.isEndorsement) {
      this.qs_master_effectiveDate_datePicker_minDate = new Date(1900, 1, 1);
      this.qs_master_effectiveDate_datePicker_maxDate = new Date(2999, 12, 31);

      switch (this.proposal.endorsementType) {
        case Enum.EndorsementType.GeneralEndorsement:
          {
            if (this.isIBGProduct) {
              this.qs_master_effectiveDate_datePicker_minDate =
                minEffectiveDate;
              this.qs_master_effectiveDate_datePicker_maxDate =
                this.proposal.qsMaster.qs_master_poi_endDate!.answer;
            } else {
              this.qs_master_effectiveDate_datePicker_minDate = new Date(
                this.proposal.qsMaster.qs_master_poi_startDate!.answer
              );
              this.qs_master_effectiveDate_datePicker_maxDate = new Date(
                this.proposal.qsMaster.qs_master_poi_endDate!.answer
              );
            }
          }
          break;
        case Enum.EndorsementType.ChangeOfCorrespondenceAddress:
        case Enum.EndorsementType.ChangeOfClientInfo:
        case Enum.EndorsementType.ChangeOfAdditionalInsured:
          {
            this.qs_master_effectiveDate_datePicker_minDate = new Date(
              this.proposal.qsMaster.qs_master_poi_startDate!.answer
            );
            this.qs_master_effectiveDate_datePicker_maxDate = new Date(
              this.proposal.qsMaster.qs_master_poi_endDate!.answer
            );
          }
          break;
        case Enum.EndorsementType.LoanRedemption:
          {
            this.qs_master_effectiveDate_datePicker_minDate =
              minEffectiveDateLoanRedemption;
            this.qs_master_effectiveDate_datePicker_maxDate = new Date(
              this.proposal.qsMaster.qs_master_poi_endDate!.answer
            );
          }
          break;
        case Enum.EndorsementType.ChangeOfPOI:
          if (this.proposal.isUnderwriter) {
            {
              this.qs_master_poi_startDate_datePicker_minDate = new Date(
                1900,
                1,
                1
              );
              this.qs_master_poi_startDate_datePicker_maxDate = new Date(
                2999,
                12,
                31
              );
            }
          }
          break;
        case Enum.EndorsementType.ChangeOfSumInsured:
          {
            this.qs_master_effectiveDate_datePicker_minDate = minEffectiveDate;
            this.qs_master_effectiveDate_datePicker_maxDate = new Date(
              this.proposal.qsMaster.qs_master_poi_endDate!.answer
            );
          }
          break;
        default:
          break;
      }
    } else if (this.isCancel) {
      switch (this.proposal.productType) {
        // case EnumSG.MortgageFireSgProduct.MIP:
        // case EnumSG.MortgageFireSgProduct.IBGMF:
        //   let MIPEffectiveDate = minEffectiveDate;
        //   if (this.proposal.loanRedeemed) {
        //     if (minEffectiveDate < minEffectiveDateLoanRedemption) {
        //       MIPEffectiveDate = minEffectiveDateLoanRedemption;
        //     }
        //   }
        //   this.qs_master_effectiveDate_datePicker_minDate = MIPEffectiveDate;
        //   this.qs_master_effectiveDate_datePicker_maxDate = new Date(
        //     this.proposal.qsMaster.qs_master_poi_endDate!.answer
        //   );
        //   break;
        default:
          this.qs_master_effectiveDate_datePicker_minDate = minEffectiveDate;
          this.qs_master_effectiveDate_datePicker_maxDate = new Date(
            this.proposal.qsMaster.qs_master_poi_endDate!.answer
          );
          break;
      }
    }
  };

  public qs_fire_coverageValueType_OnChange = (args: any): void => {
    if (
      this.selectCoverageType !==
      this.proposal.qsMaster.qs_fire_coverageValueType!.answer
    ) {
      this.proposal.qsMaster.qs_fire_building!.answer = 0;
      //clear this will set answer to undefined, but with minValue to 0
      this.proposal.qsMaster.qs_fire_replacement!.clearAnswer();

      this.selectCoverageType =
        this.proposal.qsMaster.qs_fire_coverageValueType!.answer;
    }

    this.coverageTypeService.updateValue(
      this.proposal.qsMaster.qs_fire_coverageValueType!.answer
    );
  };

  private initialize() {
    this.clientService.clientDictionary = {};
    this.nationalityList = new Array<any>();
    this.proposal = this.proposalService.proposal;
    // if (
    //   this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMF ||
    //   this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMAC
    // ) {
    //   this.isIBGProduct = true;
    // } else
    {
      this.defaultNOI = "Additional Insured";
      this.editableNOI = false;
    }
    this.isIncomplete =
      this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete
        ? true
        : false;
    if (
      common.isDefined(this.proposal.originalInceptionDate) &&
      this.proposal.originalInceptionDate !== null
    ) {
      this.originalInceptionDate = dateFormatter.format(
        this.proposal.originalInceptionDate,
        Culture.enSg
      );
    } else {
      this.originalInceptionDate = dateFormatter.format(
        this.proposal!.qsMaster.qs_master_poi_startDate!.answer,
        Culture.enSg
      );
      this.proposal.originalInceptionDate =
        this.proposal!.qsMaster.qs_master_poi_startDate!.answer;
    }
    if (
      this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === "True"
    ) {
      this.manualUnderwritting();
    }
    super.subscribeTabLoadRenderer();

    this.fireRiskDetailsOccupiedAsValue();
    this.qs_fire_replacement_questionUpdate();

    this.setProduct(); //has to be done before getDisplayManualUnderwriting()
    this.getDisplayManualUnderwriting();
  }

  private getDisplayManualUnderwriting = () => {
    this.displayManualUnderwriting = this.productFactory
      .master()
      .showManualUnderwriting(this.identityRolesService, this.proposal);
  };

  // private resetAuxiliaryModel = () => {
  //     // reset the aux if underwriter changes it
  //     if (this.isUnderwriter && this.isIBGProduct) {
  //         const data = {
  //             message: 'Premium Details will be refreshed after saved.'
  //         };

  //         this._dialog = Dialog.DialogModal.Instance.modalLarge(
  //             'shared/partials/mdInformationMessage/informationMessageController.html',
  //             'InformationMessageController', data
  //             , '', undefined, undefined, undefined, false, 'standardModal');

  //         this.proposal.auxiliary = {};
  //     }
  // }

  public qs_master_insuredIndustry_onChange = () => {
    let postData: occupationSearchDlgInputArgs = {
      isEditable: this.isEditable,
      occupationList: this.proposal.qsMaster.qs_master_insuredIndustry!.options, //temp pass to inside ClientSearchComponent
    };

    const occupationSearchDialogRef = this.cdkDialogService.open(
      OccupationSearchComponent,
      {
        data: postData,
      }
    );

    occupationSearchDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForOccupation(result);
      }
    });
  };

  private occupationModalClose = (data: KeyValue<string>): void => {
    if (common.isDefined(data)) {
      this.proposal.qsMaster.qs_master_insuredIndustry!.answer = data.key;
      this.proposal.qsMaster.qs_master_insuredIndustry_desc!.answer =
        data.value;
    }
  };

  fetchEquipmentOptions = (): KeyValue<any>[] => {
    const selectedMasterPolicyDetails = this.getMasterPolicyDetails();
    this.equipmentDropdownOptions =
      this.proposalService.proposal.qsMaster.qs_machinery_equipment!.options.filter(
        (obj: any) => {
          switch (selectedMasterPolicyDetails.policyType) {
            case "ARC":
              return (
                obj.value ===
                  mortgageFireSingaporeSettings.ARC_MedicalMachineryType ||
                obj.value ===
                  mortgageFireSingaporeSettings.ARC_OfficeMachineryType ||
                obj.value ===
                  mortgageFireSingaporeSettings.ARC_ConstructionMachineryType ||
                obj.value ===
                  mortgageFireSingaporeSettings.ARC_IndustrialMachineryType
              );
            case "MAR":
              return (
                obj.value ===
                  mortgageFireSingaporeSettings.MAR_ConstructionMachineryType ||
                obj.value ===
                  mortgageFireSingaporeSettings.MAR_CranesMachineryType ||
                obj.value ===
                  mortgageFireSingaporeSettings.MAR_IndustrialMachineryType
              );
            default:
              return null;
          }
        }
      );
    return this.equipmentDropdownOptions;
  };

  verifyStateCountry(answer: string) {
    const elementPos = this.countryExcludeState
      .map(function (x: any) {
        return x;
      })
      .indexOf(answer);
    if (elementPos > -1) {
      return false;
    }
    return true;
  }

  processTableDataInteresedParty(answer: string) {
    let jsonParsed: any = [];
    if (common.isDefined(answer)) {
      jsonParsed = JSON.parse(answer);
      /*add id for table's use*/
      jsonParsed.forEach((item: { PartyID: any }, index: any) => {
        item.PartyID = index;
      });
    }
    return jsonParsed;
  }

  processTableDataOtherInsured(answer: string) {
    let jsonParsed: any = [];
    if (common.isDefined(answer)) {
      jsonParsed = JSON.parse(answer);
      /*add id for table's use*/
      jsonParsed.forEach((item: { otherInsuredID: any }, index: any) => {
        item.otherInsuredID = index;
      });
    }
    return jsonParsed;
  }

  processTableDataEquipment(answer: string) {
    let jsonParsed: any = [];
    if (common.isDefined(answer)) {
      jsonParsed = JSON.parse(answer);
      /*add id for table's use*/
      jsonParsed.forEach(
        (item: { rowID: any; additionalInfo: string }, index: any) => {
          item.rowID = index;
          //  addtional info is added later, so need to check on the value to prevent showing undefined
          if (!Global.isDefined(item.additionalInfo)) {
            item.additionalInfo = "";
          }
        }
      );
    }
    return jsonParsed;
  }

  // onDataBound = () => {
  //     const gridTable: any = $('#qs_master_claimedBefore_table_grid').data('kendoGrid');

  // }

  // claimsData_OnRead = (options): void => {
  //     options.success(this.claimData);
  // }

  // claimsData_OnCreate = (options): void => {
  //     if (options.data && options.data.models) {

  //         this.claimDataLength = this.claimDataLength + 1;
  //         /*adding the id using the initial length*/
  //         options.data.models[0].PartyID = this.claimDataLength;
  //         this.claimData.push(options.data.models[0]);
  //         options.success(options.data.models[0]);
  //         /*TODO - need to bind to the qas*/
  //         this.proposal.qsMaster.qs_fire_interestedParties.answer = JSON.stringify(this.claimData);
  //     }
  // }

  // claimData_OnDestroy = (options): void => {
  //     if (options.data && options.data.models) {
  //         for (let i = 0; i < this.claimData.length; i++) {
  //             const updatedData: any = options.data.models[0];
  //             if (this.claimData[i].PartyID === updatedData.PartyID) {
  //                 this.claimData.splice(i, 1);
  //                 break;
  //             }
  //         }
  //         /*set the answer to empty if length < 1*/
  //         this.proposal.qsMaster.qs_fire_interestedParties.answer = (this.claimData.length > 0) ? JSON.stringify(this.claimData) : '';
  //         options.success(this.claimData);
  //     }
  // }

  // claimData_OnUpdate = (options): void => {
  //     if (options.data && options.data.models) {
  //         for (let i = 0; i < this.claimData.length; i++) {
  //             const updatedData: any = options.data.models[0];
  //             if (this.claimData[i].PartyID === updatedData.PartyID) {
  //                 this.claimData[i].Name = updatedData.Name;
  //                 this.claimData[i].NatureOfInterest = updatedData.NatureOfInterest;
  //             }
  //         }
  //         options.success(options.data.models[0]);
  //         this.proposal.qsMaster.qs_fire_interestedParties.answer = JSON.stringify(this.claimData);
  //     }
  // }

  equipmentData_OnCreate = (
    equipmentListingEntity: MfSgEquipmentListingEntity[]
  ): void => {
    if (common.isDefined(equipmentListingEntity)) {
      this.proposalService.proposal.qsMaster.qs_machinery_equipment!.answer =
        JSON.stringify(equipmentListingEntity);
    }
  };

  equipmentData_OnDestroy = (
    equipmentListingEntity: MfSgEquipmentListingEntity[]
  ): void => {
    if (common.isDefined(equipmentListingEntity)) {
      this.proposalService.proposal.qsMaster.qs_machinery_equipment!.answer =
        JSON.stringify(equipmentListingEntity);
    }
  };

  equipmentData_OnUpdate = (
    equipmentListingEntity: MfSgEquipmentListingEntity[]
  ): void => {
    if (common.isDefined(equipmentListingEntity)) {
      this.proposalService.proposal.qsMaster.qs_machinery_equipment!.answer =
        JSON.stringify(equipmentListingEntity);
    }
  };

  ngOnDestroy() {
    this.unsubscribeRxjs();
  }

  clientCreate() {
    const postData: clientCreateDlgInputArgs = {
      isNewClient: true,
      proposal: this.proposal,
    };
    const clientCreateDialogRef = this.cdkDialogService.open(
      MfHkClientCreateComponent,
      {
        data: postData,
      }
    );
    clientCreateDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }

  updatePreferredDelivery = () => {
    if (this.proposalService.proposal.customerMappingId! > 0) {
      const postData = {
        isNewClient: true,
        clientCode:
          this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer,
      };
      const preferredDeliveryMethodDialogRef = this.cdkDialogService.open(
        MdPreferredDeliveryMethodComponent,
        {
          data: postData,
        }
      );

      preferredDeliveryMethodDialogRef
        .afterClosed()
        .subscribe((result: any) => {
          // Subscription runs after the dialog closes
          if (result) {
            this.confirmationModalCloseForDeliveryMehodActions(result);
          }
        });
    }
  };

  clientSearch = () => {
    let postData: mfHkClientSearchDlgInputArgs = {
      isEditable: this.isEditable,
      proposal: this.proposal, //temp pass to inside ClientSearchComponent
    };
    const clientSearchDialogRef = this.cdkDialogService.open(
      MfHkClientSearchComponent,
      {
        data: postData,
      }
    );
    clientSearchDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  };

  viewClientDetails = () => {
    const postData = {
      isEditable: this.isEditable,
      clientCode:
        this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer,
    };

    const v1DialogRef = this.cdkDialogService.open(ClientViewComponent, {
      data: postData,
    });

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.UpdateClientInfo(result);
      }
    });
  };

  correspondanceDeliveryAddress(data: any) {
    let contactInfo = "";
    if (
      this.proposal.proposalStatus == Enum.ProposalStatus.Incomplete &&
      data != null
    ) {
      if (data["address"][0]["countryISO2"] === "SG") {
        contactInfo =
          this.getAddressFormat(data["address"][0]["addressLine1"]) + "|";
        if (this.getAddressDefined(data["address"][0]["addressLine2"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["addressLine2"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["unitNo"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["unitNo"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["buildingName"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["buildingName"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["countryISO2"])) {
          contactInfo =
            contactInfo +
            this.addressCountryIfUnknown(data["address"][0]["countryISO2"]) +
            " |";
        }
        if (this.getAddressDefined(data["address"][0]["postCode"])) {
          contactInfo =
            contactInfo + this.getAddressFormat(data["address"][0]["postCode"]);
        }
      } else {
        contactInfo = this.getAddressFormat(data["address"][0]["unitNo"]) + "|";
        if (this.getAddressDefined(data["address"][0]["buildingName"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["buildingName"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["addressLine1"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["addressLine1"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["addressLine2"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["addressLine2"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["addressLine3"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["addressLine3"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["addressLine4"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["addressLine4"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["postCode"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["postCode"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["city"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["city"]) +
            "|";
        }
        if (this.getAddressDefined(data["address"][0]["stateName"])) {
          contactInfo =
            contactInfo +
            this.getAddressFormat(data["address"][0]["stateName"]) +
            "|";
        }
        //DBSEP-7143
        // if (this.getAddressDefined(data["address"][0]["countryISO2"])) {
        //   contactInfo =
        //     contactInfo +
        //     this.addressCountryIfUnknown(data["address"][0]["countryISO2"]) +
        //     "|";
        // }
      }
    } else {
      contactInfo =
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!
          .answer;
    }
    var dataAddress = contactInfo.split("|");
    var address = "";
    if (contactInfo.includes("|")) {
      for (let x in dataAddress) {
        if (dataAddress[x] != " " || dataAddress[x] != "") {
          address = address + " " + dataAddress[x];
        }
      }
    } else {
      address = contactInfo;
    }

    this.contactInformationPreferredDelivery = address;
    this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.answer =
      contactInfo;
  }

  confirmationModalCloseForDeliveryMehodActions = (response: any) => {
    if (response.status === "yes") {
      let data = response.data;
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.answer =
        data.mobileNumber;
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!.answer =
        data.emailAddress;
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer =
        data.preferredDeliveryMethod;
      this.correspondanceDeliveryAddress(data);
    }
  };

  confirmationModalCloseForOccupation = (response: any): void => {
    if (response.status === "yes" && common.isDefined(response.data)) {
      this.proposal.qsMaster.qs_master_insuredIndustry!.answer =
        response.data.key;
      this.proposal.qsMaster.qs_master_insuredIndustry_desc!.answer =
        response.data.value;
    }
  };

  confirmationModalCloseForActions = (response: any): void => {
    if (response.status === "yes") {
      let data = response.data;
      if (this.isChangeOfClientInfo && !data.isNewClient) {
        this.confirmationModalCloseForChangeOfClientInfoController(data);
        this.confirmationModalCloseForChangeOfCorrespondenceAddress(data);
      } else {
        let clientId = data.clientId;
        let clientAddressId = data.clientAddressID;
        let clientCode = data.clientCode;

        //if current level not found, dive another level, maybe diff api
        if (
          !common.isDefined(clientId) &&
          common.isDefined(data.clientData) &&
          common.isDefined(data.clientAddressData)
        ) {
          clientId = (data.clientData as ClientEntity).clientId;
          clientAddressId = (data.clientAddressData as ClientAddressEntity)
            .clientAddressID;
          clientCode = data.clientCode;
        }
        if (common.isDefined(clientId)) {
          // map to customer mapping id
          this.proposalService.proposal.customerMappingId = clientId;
          this.proposalService.proposal.customerMappingAddressID =
            clientAddressId;
          // this.ClientService.search();

          this.clientService.get(clientId, clientCode).subscribe((response) => {
            if (!this.isChangeOfClientInfo) this.isEditable = true;
            // if (response["clientType"] === 1) {
            //   this.proposal.qsMaster.qs_master_insuredName!.answer =
            //     response["lastName"] + " " + response["firstName"];
            //   this.proposal.qsMaster.qs_master_clientName!.answer =
            //     response["lastName"] + "-" + response["firstName"];
            // } else
            {
              this.proposal.qsMaster.qs_master_insuredName!.answer =
                response["clientName"];
              this.proposal.qsMaster.qs_master_clientName!.answer =
                response["clientName"];
            }

            this.proposal.qsMaster.qs_master_insuredNo!.answer =
              response["clientCode"];
            this.proposal.qsMaster.qs_master_insuredNoDocType!.answer =
              response["docType"];
            this.proposal.qsMaster.qs_master_mobileNumber!.answer =
              response["mobileNumber"];
            this.proposal.qsMaster.qs_master_emailID!.answer =
              response["emailAddress"];
            // update the values to the master level
            this.proposal.qsMaster.qs_master_insuredAddress_building!.answer =
              response["address"][0]["buildingName"];
            this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.answer =
              response["address"][0]["unitNo"];
            this.proposal.qsMaster.qs_master_insuredAddress1!.answer =
              response["address"][0]["addressLine1"];
            this.proposal.qsMaster.qs_master_insuredAddress2!.answer =
              response["address"][0]["addressLine2"];
            this.proposal.qsMaster.qs_master_insuredAddress3!.answer =
              response["address"][0]["addressLine3"];
            this.proposal.qsMaster.qs_master_insuredAddress4!.answer =
              response["address"][0]["addressLine4"];
            this.proposal.qsMaster.qs_master_insuredPostalCode!.answer =
              response["address"][0]["postCode"];
            this.proposal.qsMaster.qs_master_insuredCity!.answer =
              response["address"][0]["city"];
            this.proposal.qsMaster.qs_master_insuredState!.answer =
              response["address"][0]["stateName"];
            this.proposal.qsMaster.qs_master_insuredCountry!.answer =
              response["address"][0]["countryISO2"];

            let contactInfo = "";
            if (response["address"][0]["countryISO2"] === "SG") {
              contactInfo =
                response["address"][0]["addressLine1"] +
                " " +
                this.getAddressFormat(response["address"][0]["addressLine2"]) +
                this.getAddressFormat(response["address"][0]["unitNo"]) +
                this.getAddressFormat(response["address"][0]["buildingName"]) +
                this.addressCountryIfUnknown(
                  response["address"][0]["countryISO2"]
                ) +
                " " +
                this.getAddressFormat(response["address"][0]["postCode"]);
            } else {
              contactInfo =
                this.getAddressFormat(response["address"][0]["unitNo"]) +
                this.getAddressFormat(response["address"][0]["buildingName"]) +
                response["address"][0]["addressLine1"] +
                " " +
                this.getAddressFormat(response["address"][0]["addressLine2"]) +
                this.getAddressFormat(response["address"][0]["postCode"]) +
                this.getAddressFormat(response["address"][0]["city"]) +
                this.getAddressFormat(response["address"][0]["stateName"]); //+
              //DBSEP-7143
              // this.addressCountryIfUnknown(
              //   response["address"][0]["countryISO2"]
              // );
            }
            this.contactInformation = contactInfo;

            this.setPreferredDeliveryMethod(response);
          });
        }

        this.copyInsuredAddress(clientId, clientCode);
      }
    } else {
      if (common.isDefined(this.clientDictionary)) {
        this.mapClientdata(
          this.clientDictionary,
          this.clientService.clientDictionary[
            this.proposalService.proposal.customerMappingId!
          ]
        );
      }
    }
  };

  setPreferredDeliveryMethod(response: any) {
    if (
      this.proposal.proposalStatus == Enum.ProposalStatus.Incomplete &&
      (this.proposal.transType === Enum.TransType.NEW ||
        this.proposal.transType === Enum.TransType.REN)
    ) {
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.answer =
        response["mobileNumber"];

      this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!.answer =
        response["emailAddress"];
      this.correspondanceDeliveryAddress(response);
      if (
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!
          .answer == "0"
      ) {
        this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.answer =
          "";
      }

      //DBSEP-7006
      // if (
      //   this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.isAnswered()
      // ) {
      //   this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer =
      //     "5";
      // } else if (
      //   this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!.isAnswered()
      // ) {
      //   this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer =
      //     "2";
      // } else {
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer = "1";
      // }
    }
  }

  qs_fire_replacement_questionUpdate = (): void => {
    switch (this.proposal.productType) {
      //case EnumSG.MortgageFireSgProduct.HDB:
      case EnumHK.MortgageFireHKProduct.HSCMB:
      case EnumHK.MortgageFireHKProduct.HSWPB:
        this.proposal.qsMaster.qs_fire_replacement!.isVisible = false;
        this.proposal.qsMaster.qs_fire_replacement!.required = false;
        break;
      default:
        break;
    }
  };

  qs_master_masterPolicyNumber_dropDownListUpdate = (): void => {
    switch (this.proposal.productType) {
      case EnumHK.MortgageFireHKProduct.HSCMB:
      case EnumHK.MortgageFireHKProduct.HSWPB:
        //case EnumSG.MortgageFireSgProduct.HDB:
        this.proposal.qsMaster.qs_master_masterPolicyNumber!.options = [
          {
            key: "Premium Paid by Bank (2 Years Free) - DHO (26241776)",
            value: "26241776",
          },
          {
            key: "Premium Paid by Bank (5 Years Free) - DHO (26465408)",
            value: "26465408",
          },
          {
            key: "Premium Paid by Bank (10 Years Free) - DHO (26415044)",
            value: "2641504",
          },
          { key: "Customer - Auto Debit - DFW (26412347)", value: "26412347" },
          {
            key: "Premium Paid by Bank (1st Year Free) - DDM (26737306)",
            value: "26737306",
          },
          { key: "Customer - Auto Debit - DDM (26858221)", value: "26858221" },
        ];
        break;
      // case EnumSG.MortgageFireSgProduct.MIP:
      //   this.proposal.qsMaster.qs_master_masterPolicyNumber!.options = [
      //     {
      //       key: "Premium Paid by Bank (1st Year Free) - DFW (26737301)",
      //       value: "26737301",
      //     },
      //     { key: "Customer - Auto Debit - DFW (26858210)", value: "26858210" },
      //     {
      //       key: "Premium Paid by Bank (1st Year Free) - DFW (27387828)",
      //       value: "27387828",
      //     },
      //     { key: "Customer - Auto Debit - DFW (27395036)", value: "27395036" },
      //   ];
      //   break;
      // case EnumSG.MortgageFireSgProduct.PTE:
      //   this.proposal.qsMaster.qs_master_masterPolicyNumber!.options = [
      //     { key: "Premium Paid by Bank - FHO (06004951)", value: "06004951" },
      //     {
      //       key: "Premium Paid by Bank (2 years free) - FHO (26475515)",
      //       value: "26475515",
      //     },
      //     {
      //       key: "Premium Paid by Bank (3 years free) - FHO (26475550)",
      //       value: "26475550",
      //     },
      //     {
      //       key: "Premium Paid by Bank (1st Year Free) - DHW (26737312)",
      //       value: "26737312",
      //     },
      //     { key: "Premium Paid by Bank - FDM (06005204)", value: "06005204" },
      //     {
      //       key: "Premium Paid by Bank (Cheque Payment) - FDM (06616126)",
      //       value: "06616126",
      //     },
      //     {
      //       key: "Customer - Auto Debit - FDM (Staff) (26665692)",
      //       value: "26665692",
      //     },
      //   ];
      //   break;
      // case EnumSG.MortgageFireSgProduct.IBGMF:
      //   this.proposal.qsMaster.qs_master_masterPolicyNumber!.options = [
      //     { key: "Premium Paid by Bank - FHO (06004951)", value: "06004951" },
      //     {
      //       key: "Premium Paid by Bank (2 years free) - FHO (26475515)",
      //       value: "26475515",
      //     },
      //     {
      //       key: "Premium Paid by Bank (3 years free) - FHO (26475550)",
      //       value: "26475550",
      //     },
      //     {
      //       key: "Premium Paid by Bank (1st Year Free) - DHW (26737312)",
      //       value: "26737312",
      //     },
      //     { key: "Premium Paid by Bank - FDM (06005204)", value: "06005204" },
      //     {
      //       key: "Premium Paid by Bank (Cheque Payment) - FDM (06616126)",
      //       value: "06616126",
      //     },
      //     {
      //       key: "Customer - Auto Debit - FDM (Staff) (26665692)",
      //       value: "26665692",
      //     },
      //   ];
      //   break;
      default:
        break;
    }
  };

  qs_master_poi_startDate_onChange = (): void => {
    /// 12/10/2018 : Chirag Added Code For FreeInsurance Start/End Date
    this.setFreeInsuranceDate();
    const annualExpiryDate = cloneDeep(
      this.proposal.qsMaster.qs_master_poi_startDate!.answer
    );
    const minInceptionDate = new Date();
    annualExpiryDate.setYear(annualExpiryDate.getFullYear() + 1);
    annualExpiryDate.setDate(annualExpiryDate.getDate() - 1);
    minInceptionDate.setDate(minInceptionDate.getDate() - 30);
    if (this.proposal.isBroker) {
      const timeDiff = Math.abs(
        new Date().getTime() -
          this.proposal.qsMaster.qs_master_poi_startDate!.answer.getTime()
      );
      const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
      this.qs_master_poi_startDate_datePicker_minDate = minInceptionDate;
      this.qs_master_poi_startDate_datePicker_maxDate = annualExpiryDate;
    } else {
      this.qs_master_poi_startDate_datePicker_minDate = new Date(1900, 1, 1);
      this.qs_master_poi_startDate_datePicker_maxDate = new Date(2999, 12, 31);
    }
    this.proposal.qsMaster.qs_master_poi_endDate!.answer = annualExpiryDate;
    // Update the effective Date whenever start date is modified
    this.proposal.qsMaster.qs_master_effectiveDate!.answer =
      this.proposal.qsMaster.qs_master_poi_startDate!.answer;
    this.qs_master_effectiveDate_onChange();
    switch (this.proposal.proposalStatus) {
      case Enum.ProposalStatus.Incomplete:
        //DBSEP-2136
        if (
          this.identityRolesService.isOperationUnderwriter &&
          this.isIBGProduct
        ) {
          // if (
          //   this.proposal.productType == EnumSG.MortgageFireSgProduct.IBGMF ||
          //   this.proposal.productType == EnumSG.MortgageFireSgProduct.IBGMAC
          // ) {
          //   this.proposalService.businessRulesManager.validatePOIBackDated();
          // }
        }
        break;
      case Enum.ProposalStatus.PendingChecker:
        if (!this.proposal.isUnderwriter) {
          this.proposalService.businessRulesManager.validatePOIBackDated();
        }
        break;
      case Enum.ProposalStatus.Referred:
        this.proposalService.businessRulesManager.validatePOIBackDated();
        break;
    }
  };

  fireRiskDetailsOccupiedAsValue = (): void => {
    switch (this.proposal.productType) {
      case EnumHK.MortgageFireHKProduct.HSCMB:
      case EnumHK.MortgageFireHKProduct.HSWPB:
        //case EnumSG.MortgageFireSgProduct.HDB:
        this.currency1HDBDisabled = false;
        break;
      // case EnumSG.MortgageFireSgProduct.MIP:
      //   this.proposal.qsMaster.qs_fire_mcst!.isVisible = true;
      //   break;
      // case EnumSG.MortgageFireSgProduct.PTE:
      //   break;
      // case EnumSG.MortgageFireSgProduct.IBGMF:
      //   //  this.proposal.qsMaster.qs_fire_occupied.answer = '53';
      //   // this.proposal.qsMaster.qs_fire_occupied.options.splice(1, 5);
      //   // this.readOnlyQSFireOccupied();
      //   break;
      default:
        break;
    }
  };

  readOnlyQSFireOccupied = (): void => {
    this.proposal.qsMaster.qs_fire_occupied!.readOnly = true;
    // this.proposal.qsMaster.qs_fire_occupied.answer = this.proposal.qsMaster.qs_fire_occupied.options[0].key; // Default to Dwelling
  };

  // private qs_fire_sumInsured_ibg() {
  //   this.proposalService.businessRulesManager.validateIBGSI();
  // }

  qs_fire_occupied_onChange = (data: any): void => {
    switch (this.proposal.productType) {
      case EnumHK.MortgageFireHKProduct.HSCMB:
      case EnumHK.MortgageFireHKProduct.HSWPB:
        //do nothing for now
        break;
      default:
    }
  };

  qs_fire_FSVSumInsured = (data: any): void => {
    //no needed anymore
    // switch (this.proposal.productType) {
    //   case EnumHK.MortgageFireHKProduct.HSCMB:
    //   case EnumHK.MortgageFireHKProduct.HSWPB:
    //     //case EnumSG.MortgageFireSgProduct.HDB:
    //     if (
    //       this.proposal.qsMaster.qs_master_manualUnderwritting!.answer ===
    //         "True" ||
    //       this.proposal.qsMaster.qs_master_manualUnderwritting!.answer === true
    //     ) {
    //       this.proposal.qsMaster.qs_fire_building!.answer =
    //         this.proposal.qsMaster.qs_fire_building!.answer;
    //     } else {
    //       this.proposal.qsMaster.qs_fire_building!.answer =
    //         this.proposal.qsMaster.qs_fire_replacement!.answer;
    //     }
    //     break;
    //   default: // this.currency1HDBValue = (data * 1.2);
    //     this.proposal.qsMaster.qs_fire_building!.answer = data;
    //     break;
    // }
    // switch (this.proposal.productType) {
    //   case EnumSG.MortgageFireSgProduct.IBGMF:
    //     this.qs_fire_sumInsured_ibg();
    //     break;
    //   default:
    // }
  };

  /// 12/10/2018 : Chirag Added Method For FreeInsurance Start/End Date
  setFreeInsuranceDate = (): void => {
    if (!this.isIBGProduct) {
      this.NumOfFreeYears = 0;
      let dtOriginaInceptionDate = "";

      if (
        this.proposal.transType === Enum.TransType.NEW &&
        common.isDefined(this.houseHoldContentsAmount)
      ) {
        /// 08/10/2019 :INC20585048 CTS team added code block For FreeInsurance Start/End Date & Remaining Free Year Issue
        if (
          Number(
            cloneDeep(
              this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer
            )
          ) > 0 &&
          this.proposal.proposalStatus !== 6
        ) {
          this.NumOfFreeYears = Number(
            cloneDeep(
              this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer
            )
          );
        } else {
          this.NumOfFreeYears = Number(
            this.houseHoldContentsAmount?.noOfFreeInsuranceYear
          );
        }
      } else if (
        Number(
          cloneDeep(this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer)
        ) > 0
      ) {
        this.NumOfFreeYears = Number(
          cloneDeep(this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer)
        );
      }

      this.proposal.qsMaster.qs_master_FreeIns_startDate!.isVisible =
        this.NumOfFreeYears > 0 ? true : false;
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.isVisible =
        this.NumOfFreeYears > 0 ? true : false;

      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer =
        this.NumOfFreeYears;

      const FreeInsDate = cloneDeep(
        this.proposal.qsMaster.qs_master_poi_startDate!.answer
      );

      this.proposal.qsMaster.qs_master_FreeIns_startDate!.answer = new Date(
        FreeInsDate
      );
      const FreeInsExpiryDate = cloneDeep(
        this.proposal.qsMaster.qs_master_FreeIns_startDate!.answer
      );

      FreeInsExpiryDate.setYear(
        FreeInsExpiryDate.getFullYear() + this.NumOfFreeYears
      );
      FreeInsExpiryDate.setDate(FreeInsExpiryDate.getDate() - 1);
      this.proposal.qsMaster.qs_master_FreeIns_endDate!.answer =
        FreeInsExpiryDate;

      dtOriginaInceptionDate = this.proposal.originalInceptionDate;
      const OriginalDate = new Date(dtOriginaInceptionDate);

      if (
        Number(
          cloneDeep(this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer)
        ) == 0 &&
        common.isDefined(this.houseHoldContentsAmount) &&
        Number(this.houseHoldContentsAmount?.noOfFreeInsuranceYear) > 0 &&
        Number(OriginalDate.getFullYear()) != Number(FreeInsDate.getFullYear())
      ) {
        this.isFreeInsApplicable = false;
      }
    } else {
      let FreeYears = 0;
      let dtOriginaInceptionDate = "";

      FreeYears = Number(
        cloneDeep(this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer)
      );

      this.proposal.qsMaster.qs_master_FreeIns_startDate!.isVisible =
        FreeYears > 0 ? true : false;
      dtOriginaInceptionDate =
        this.proposal.qsMaster.qs_master_poi_startDate!.answer;

      this.proposal.qsMaster.qs_master_FreeIns_startDate!.answer = new Date(
        dtOriginaInceptionDate
      );
      const FreeInsExpiryDate = cloneDeep(
        this.proposal.qsMaster.qs_master_FreeIns_startDate!.answer
      );

      FreeInsExpiryDate.setYear(FreeInsExpiryDate.getFullYear() + FreeYears);
      FreeInsExpiryDate.setDate(FreeInsExpiryDate.getDate() - 1);
      this.proposal.qsMaster.qs_master_FreeIns_endDate!.answer =
        FreeInsExpiryDate;
    }
  };

  setFreeInsuranceDateForCBG = (): void => {
    this.NumOfFreeYears = 0;
    this.NumOfFreeYears = Number(
      cloneDeep(this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.answer)
    );
    if (this.NumOfFreeYears > 0) {
      const FreeInsExpiryDate = cloneDeep(
        this.proposal.qsMaster.qs_master_FreeIns_startDate!.answer
      );
      FreeInsExpiryDate.setYear(
        FreeInsExpiryDate.getFullYear() + this.NumOfFreeYears
      );
      FreeInsExpiryDate.setDate(FreeInsExpiryDate.getDate() - 1);
      this.proposal.qsMaster.qs_master_FreeIns_endDate!.answer =
        FreeInsExpiryDate;
    } else {
      this.proposal.qsMaster.qs_master_FreeIns_startDate!.isVisible =
        this.NumOfFreeYears > 0 ? true : false;
      this.proposal.qsMaster.qs_fire_freeInsuranceNoYear!.isVisible =
        this.NumOfFreeYears > 0 ? true : false;
    }
  };

  /// 15/11/2018 : Chirag Method For FreeInsurance Start/End Date
  setFreeInsurance_OnPOIDate_Endorsement = (): void => {
    ///Chirag: 28-12-2018 Free Insurance Change For CBG Prodcut
    if (this.isEndorsement && this.proposal.transType !== Enum.TransType.REN) {
      this.setFreeInsuranceDate();
    }
  };

  // /// 30/09/2019 : INC20585048 DBS EPL - FREE INSURANCE DISPLAY DISCREPANCIES and Occcupation Filter issue
  // filterOccupied = (data: any): void => {
  //     const dropdownFireOccupied = $('#qs_fire_occupied_dropdown').data('kendoDropDownList');
  //     if (common.isDefined(data) && data != null) {
  //         this.houseHoldContentsAmount = this.masterPolicyService.get(data.trim().replace(' ', ''));

  //         if (Global.isDefined(this.houseHoldContentsAmount) && this.houseHoldContentsAmount.policyType === 'DHO') {
  //             if (Global.isDefined(dropdownFireOccupied)) {
  //                 dropdownFireOccupied.dataSource.filter([{
  //                     field: 'value',
  //                     operator: 'neq',
  //                     value: 7
  //                 },
  //                 {
  //                     field: 'value',
  //                     operator: 'neq',
  //                     value: 8
  //                 }

  //                 ]);
  //                 if (dropdownFireOccupied.value() === '7' || dropdownFireOccupied.value() === '8') {
  //                     dropdownFireOccupied.text('');
  //                     dropdownFireOccupied.value('');
  //                     this.proposal.qsMaster.qs_fire_occupied.clearAnswer();
  //                 }
  //             }
  //         } else {
  //             if (Global.isDefined(dropdownFireOccupied)) {
  //                 dropdownFireOccupied.dataSource.filter({});
  //             }
  //         }
  //     }
  // }

  qs_master_masterPolicyNumber_onChange = (data: any): void => {
    if (common.isDefined(data) && data != null) {
      this.houseHoldContentsAmount =
        this.masterPolicyService.getbyMasterPolicyId(
          data.trim().replace(" ", "")
        );
      switch (this.proposal.productType) {
        case EnumHK.MortgageFireHKProduct.HSCMB:
        case EnumHK.MortgageFireHKProduct.HSWPB:
          // case EnumSG.MortgageFireSgProduct.HDB:
          // case EnumSG.MortgageFireSgProduct.PTE:
          // case EnumSG.MortgageFireSgProduct.MIP:
          /// 12/10/2018 : Chirag Added Code For FreeInsurance Start/End Date
          this.setFreeInsuranceDate();
          if (
            common.isDefined(this.houseHoldContentsAmount) &&
            this.houseHoldContentsAmount != null
          ) {
            this.proposal.qsMaster.qs_fire_householdContents!.answer =
              this.houseHoldContentsAmount.homeContentSumInsuredAmount.toString();
          } else {
            this.proposal.qsMaster.qs_fire_householdContents!.answer = "0";
          }
          break;
        // case EnumSG.MortgageFireSgProduct.IBGMF:
        //   if (
        //     common.isDefined(this.houseHoldContentsAmount) &&
        //     this.houseHoldContentsAmount != null
        //   ) {
        //     this.proposal.qsMaster.qs_master_miRate!.answer = this
        //       .houseHoldContentsAmount.isMI
        //       ? "1"
        //       : "2";
        //   }
        //   break;
        // case EnumSG.MortgageFireSgProduct.IBGMAC:
        //   if (!this.suppressNotification) {
        //     const equipmentData: any =
        //       common.isDefined(
        //         this.proposalService.proposal.qsMaster.qs_machinery_equipment!
        //           .answer
        //       ) &&
        //       this.proposalService.proposal.qsMaster.qs_machinery_equipment!
        //         .answer !== ""
        //         ? JSON.parse(
        //             this.proposalService.proposal.qsMaster
        //               .qs_machinery_equipment!.answer
        //           )
        //         : undefined;
        //     this.fetchEquipmentOptions();
        //     if (
        //       !this.firstOnLoad &&
        //       common.isDefined(equipmentData) &&
        //       equipmentData.length > 0
        //     ) {
        //       const confirmationData = {
        //         message: this.msg_masterPolicyChangeWarning,
        //       };
        //       let v1InputData: v1DlgInputArgs = {
        //         opened: true,
        //         dialogWidth: 600,
        //         data: {},
        //         message: this.msg_masterPolicyChangeWarning,
        //       };

        //       const v1DialogRef = this.cdkDialogService.open(
        //         MdConfirmationMessageV1Component,
        //         {
        //           data: v1InputData,
        //         }
        //       );

        //       v1DialogRef.afterClosed().subscribe((result: any) => {
        //         // Subscription runs after the dialog closes
        //         if (result) {
        //           this.confirmationModalCloseForMasterPolicyNumberChange(
        //             result
        //           );
        //         }
        //       });
        //     } else {
        //       this.masterPolicyNumber =
        //         this.proposal.qsMaster.qs_master_masterPolicyNumber!.answer;
        //     }
        //   }
        //   break;
        default:
          // do nothing
          break;
      }
      if (
        common.isDefined(this.houseHoldContentsAmount) &&
        this.houseHoldContentsAmount != null
      ) {
        // set required if not the cheque payment = 2
        if (
          Global.isDefined(this.proposal.qsMaster.qs_master_bankReferNumber)
        ) {
          this.proposal.qsMaster.qs_master_bankReferNumber!.required =
            this.houseHoldContentsAmount.paymentType !== 2;
        }
      }
      //todo
      // // check if policy type =dho will hide the option 7 for occupied as dropdown
      // const dropdownFireOccupied = $('#qs_fire_occupied_dropdown').data('kendoDropDownList');
      // if (Global.isDefined(this.houseHoldContentsAmount) && this.houseHoldContentsAmount.policyType === 'DHO') {
      //     if (Global.isDefined(dropdownFireOccupied)) {
      //         dropdownFireOccupied!.dataSource.filter([{
      //             field: 'value',
      //             operator: 'neq',
      //             value: 7
      //         },
      //         {
      //             field: 'value',
      //             operator: 'neq',
      //             value: 8
      //         }
      //         ]);
      //         if (dropdownFireOccupied.value() === '7' || dropdownFireOccupied.value() === '8') {
      //             dropdownFireOccupied.text('');
      //             dropdownFireOccupied.value('');
      //             this.proposal.qsMaster.qs_fire_occupied.clearAnswer();
      //         }
      //     }
      // } else {
      //     if (Global.isDefined(dropdownFireOccupied)) {
      //         dropdownFireOccupied.dataSource.filter({});
      //     }
      // }
    }
  };

  confirmationModalCloseForMasterPolicyNumberChange = (data: any): void => {
    if (data.status === "yes") {
      this.suppressNotification = true;
      /**As per CR - 000308,
              clear the coverages in premium summary for every change in master policy if isPremiumOverridden property is true
          */
      // if (
      //   this.proposalService.proposal.auxiliary["isPremiumManualOverridden"] &&
      //   this.proposal.productType === EnumSG.MortgageFireSgProduct.IBGMAC
      // ) {
      //   this.proposal.refreshedProposalEntity.quotes![0].coverages = [];
      // }
      this.proposalService.proposal.qsMaster.qs_machinery_equipment!.clearAnswer();
      this.clearEquipmentListing.next(true);
      this.suppressNotification = false;
      this.masterPolicyNumber =
        this.proposalService.proposal.qsMaster.qs_master_masterPolicyNumber!.answer;
      this.fetchEquipmentOptions();
    } else {
      // set the flag to true to prevent calling the notifier upon change of the answer
      this.suppressNotification = true;
      this.proposalService.proposal.qsMaster.qs_master_masterPolicyNumber!.answer =
        this.masterPolicyNumber;
      // reset the flag back to false to call the notifier for the next change event
      this.suppressNotification = false;
    }
  };

  qs_risk_sameAsAddress_onChange = (data: any): void => {
    if (
      common.isDefined(data) &&
      data !== null &&
      !this.proposal.refreshedProposalEntity.proposalReadOnly &&
      this.proposal.transType === Enum.TransType.NEW
    ) {
      const answer = data === "1" || data === true ? true : false;
      this.isSameAsAddressTicked = answer;
      this.proposal.qsMaster.qs_risk_insuredAddress_building!.readOnly = answer;
      this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.readOnly = answer;
    } else {
      this.proposal.qsMaster.qs_risk_insuredAddress_building!.readOnly = true;
      this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.readOnly = true;
    }
  };

  public qs_master_manualUnderwritting_onChange = (data: any): void => {
    //  if (common.isDefined(data) && data !== null && data !== 'False' && this.isIncomplete) {
    if (data === true) {
      const dataMsg = {
        message: this.msg_manualUnderwritting_warning,
      };

      let v1InputData: v1DlgInputArgs = {
        opened: true,
        dialogWidth: 600,
        data: {},
        message: dataMsg.message,
      };

      const v1DialogRef = this.cdkDialogService.open(
        MdConfirmationMessageV1Component,
        {
          data: v1InputData,
        }
      );

      v1DialogRef.afterClosed().subscribe((result: any) => {
        // Subscription runs after the dialog closes
        if (result) {
          this.confirmationModalCloseForManualUnderwritting(result);
        }
      });
    } else {
      this.updateNativeElement(
        this.qs_master_manualUnderwritting_checkbox,
        false
      );
      this.proposal.qsMaster.qs_master_manualUnderwritting!.answer = "False";
    }
  };

  private confirmationModalCloseForManualUnderwritting = (data: any): void => {
    if (data.status === "yes") {
      //HE-2348: Perform save and recalculate premium when manual underwriting is checked
      this.proposalService.doAction(Enum.ProposalCommandAction.Save);
      this.proposal.auxiliary["isPremiumManualOverridden"] = true;
      this.manualUnderwritting();
      this.proposalService.businessRulesManager.validateManualUnderwritting();
      this.mfHkMessageService.sendMessage("true");
    } else {
      this.updateNativeElement(
        this.qs_master_manualUnderwritting_checkbox,
        false
      );
      this.proposal.qsMaster.qs_master_manualUnderwritting!.answer = "False";
    }
  };

  manualUnderwritting = (): void => {
    switch (this.proposal.productType) {
      case EnumHK.MortgageFireHKProduct.HSCMB:
      case EnumHK.MortgageFireHKProduct.HSWPB:
        // case EnumSG.MortgageFireSgProduct.HDB:
        // case EnumSG.MortgageFireSgProduct.PTE:
        // case EnumSG.MortgageFireSgProduct.MIP:
        this.currency1HDBDisabled = true;
        break;
    }
  };

  updateAddressValues = (
    place: { address_components: string | any[] },
    componentForm: { [x: string]: string | number },
    divId: string
  ): void => {
    this.resetAddressForm(false, divId);
    if (Global.isDefined(place.address_components)) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < place.address_components.length; i++) {
        const addressType = place.address_components[i].types[0];
        //if (componentForm[addressType]) {
        //const val = place.address_components[i][componentForm[addressType]];
        if (divId === "riskInformation_autocomplete") {
          switch (addressType) {
            case "street_number":
              let val1 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_risk_insuredAddress3!,
                val1,
                true
              );
              break;
            case "route":
              let val2 = place.address_components[i].long_name;
              //https://jira.apac.chubb.com/browse/DBSEP-6795
              this.appendAddressAns(
                this.proposal.qsMaster.qs_risk_insuredAddress3!,
                val2,
                true
              );
              break;
            case "locality":
              let val3 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_risk_insuredCity!,
                val3
              );
              break;
            case "postal_code":
              let val4 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_risk_insuredPostalCode!,
                val4,
                true
              );
              break;
            case "administrative_area_level_1":
              let val5 = place.address_components[i].short_name;
              this.updateDropdownAddressAns(
                this.proposal.qsMaster.qs_risk_insuredDistCode!,
                val5,
                true
              );
              break;
            case "country":
              //short_name is ISO code
              //long_name is 'Hong Kong', which is diff with Country table 'Hong Kong SAR'
              let val6 = place.address_components[i].short_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_risk_insuredCountry!,
                val6
              );
              break;
            default:
              break;
          }
          // clearing the search input
          //this.riskInformation_autocomplete.nativeElement.value = "";//todo
        } else if (divId === "policyInformation_autocomplete") {
          switch (addressType) {
            case "street_number":
              let val7 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_master_insuredAddress1!,
                val7,
                true
              );
              break;
            case "route":
              let val8 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_master_insuredAddress2!,
                val8,
                true
              );
              break;
            case "locality":
              let val9 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_master_insuredCity!,
                val9,
                true
              );
              break;
            case "postal_code":
              let val10 = place.address_components[i].long_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_master_insuredPostalCode!,
                val10,
                true
              );
              break;
            case "administrative_area_level_1":
              let val11 = place.address_components[i].short_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_master_insuredState!,
                val11,
                true
              );
              break;
            case "country":
              let val12 = place.address_components[i].short_name;
              this.updateAddressAns(
                this.proposal.qsMaster.qs_master_insuredCountry!,
                val12,
                true
              );
              this.isMasterStateVisible = this.verifyStateCountry(val12);
              break;
            default:
              break;
          }
          if (this.isIBGProduct) {
            this.updateContactInformation();
          }

          // clearing the search input
          // this.policyInformation_autocomplete.nativeElement.value = "";
        }
        //}
      }

      if (divId === "policyInformation_autocomplete") {
        this.copyInsuredAddress("", "");
      }
      this.checkIfAddressReadOnlyField();
    }
    //this.$scope.$apply();//todo
  };

  resetAddressForm = (fromResetButton: boolean, divId: string) => {
    if (divId === "riskInformation_autocomplete") {
      this.readOnlyAddressField = [
        "qs_risk_insuredAddress1",
        "qs_risk_insuredAddress2",
        "qs_risk_insuredCity",
        "qs_risk_insuredPostalCode",
      ];
      this.proposal.qsMaster.qs_risk_insuredAddress1!.clearAnswer();
      this.proposal.qsMaster.qs_risk_insuredAddress2!.clearAnswer();
      this.proposal.qsMaster.qs_risk_insuredAddress3!.clearAnswer();
      this.proposal.qsMaster.qs_risk_insuredDistCode!.clearAnswer();
      this.proposal.qsMaster.qs_risk_insuredPostalCode!.clearAnswer();
      this.proposal.qsMaster.qs_risk_insuredCity!.clearAnswer();
      this.proposal.qsMaster.qs_risk_insuredState!.clearAnswer();

      this.proposal.qsMaster.qs_risk_insuredAddress1!.readOnly = false;
      this.proposal.qsMaster.qs_risk_insuredAddress2!.readOnly = false;
      this.proposal.qsMaster.qs_risk_insuredPostalCode!.readOnly = true;
      this.proposal.qsMaster.qs_risk_insuredCity!.readOnly = true;
      this.proposal.qsMaster.qs_risk_insuredState!.readOnly = true;

      if (fromResetButton) {
        this.proposal.qsMaster.qs_risk_insuredAddress_building!.clearAnswer();
        this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.clearAnswer();
        // clearing the search input
        // this.riskInformation_autocomplete.nativeElement.value = ""; //todo
      }
    } else if (divId === "policyInformation_autocomplete") {
      this.readOnlyAddressField = [
        "qs_master_insuredAddress1",
        "qs_master_insuredAddress2",
        "qs_master_insuredCity",
        "qs_master_insuredPostalCode",
        "qs_master_insuredState",
      ];
      this.proposal.qsMaster.qs_master_insuredAddress1!.clearAnswer();
      this.proposal.qsMaster.qs_master_insuredAddress2!.clearAnswer();
      this.proposal.qsMaster.qs_master_insuredPostalCode!.clearAnswer();
      this.proposal.qsMaster.qs_master_insuredCity!.clearAnswer();
      this.proposal.qsMaster.qs_master_insuredState!.clearAnswer();

      if (this.isIBGProduct) {
        this.proposal.qsMaster.qs_master_insuredAddress1!.required = true;
      }
      this.proposal.qsMaster.qs_master_insuredAddress1!.readOnly = true;
      this.proposal.qsMaster.qs_master_insuredAddress2!.readOnly = true;

      this.copyInsuredAddress("", "");

      if (fromResetButton) {
        this.proposal.qsMaster.qs_master_insuredAddress_building!.clearAnswer();
        this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.clearAnswer();
        // clearing the search input
        // this.policyInformation_autocomplete.nativeElement.value = ""; //todo
      }
    }
  };

  checkIfAddressReadOnlyField = () => {
    const toCopy: boolean =
      this.qs_risk_sameAsAddress_checkbox?.nativeElement.checked;
    for (let i = 0; i < this.readOnlyAddressField.length; i++) {
      const inputFieldId = this.readOnlyAddressField[i];
      switch (inputFieldId) {
        case "qs_master_insuredAddress1":
          this.proposal.qsMaster.qs_master_insuredAddress1!.readOnly = false;
          if (toCopy) {
            this.proposal.qsMaster.qs_risk_insuredAddress1!.readOnly = false;
          }
          break;
        case "qs_master_insuredAddress2":
          this.proposal.qsMaster.qs_master_insuredAddress2!.readOnly = false;
          if (toCopy) {
            this.proposal.qsMaster.qs_risk_insuredAddress2!.readOnly = false;
          }
          break;
        case "qs_master_insuredCity":
          this.proposal.qsMaster.qs_master_insuredCity!.readOnly = false;
          if (toCopy) {
            this.proposal.qsMaster.qs_risk_insuredCity!.readOnly = false;
          }
          break;
        case "qs_master_insuredPostalCode":
          this.proposal.qsMaster.qs_master_insuredPostalCode!.readOnly = false;
          if (toCopy) {
            this.proposal.qsMaster.qs_risk_insuredPostalCode!.readOnly = false;
          }
          break;
        case "qs_master_insuredState":
          this.proposal.qsMaster.qs_master_insuredState!.readOnly = false;
          if (toCopy) {
            this.proposal.qsMaster.qs_risk_insuredState!.readOnly = false;
          }
          break;
        case "qs_risk_insuredAddress1":
          this.proposal.qsMaster.qs_risk_insuredAddress1!.readOnly = false;
          break;
        case "qs_risk_insuredAddress2":
          this.proposal.qsMaster.qs_risk_insuredAddress2!.readOnly = false;
          break;
        case "qs_risk_insuredCity":
          this.proposal.qsMaster.qs_risk_insuredCity!.readOnly = false;
          break;
        case "qs_risk_insuredPostalCode":
          this.proposal.qsMaster.qs_risk_insuredPostalCode!.readOnly = false;
          break;
        case "qs_risk_insuredState":
          this.proposal.qsMaster.qs_risk_insuredState!.readOnly = false;
          break;
        default:
          break;
      }
    }
  };

  /** editable flag - open for risk address */
  updateAddressAns = (
    model: QuestionEntity,
    val: any,
    isEditableAddress?: boolean
  ) => {
    if (val !== "") {
      model.answer = val;
      model.readOnly = isEditableAddress ? false : true;
      const index = this.readOnlyAddressField.indexOf(model.key);
      if (index !== -1) {
        this.readOnlyAddressField.splice(index, 1);
      }
    } else {
      model.readOnly = false;
    }
  };

  appendAddressAns = (
    model: QuestionEntity,
    val: any,
    isEditableAddress?: boolean
  ) => {
    if (val !== "") {
      model.answer += " " + val;
      model.readOnly = isEditableAddress ? false : true;
      const index = this.readOnlyAddressField.indexOf(model.key);
      if (index !== -1) {
        this.readOnlyAddressField.splice(index, 1);
      }
    } else {
      model.readOnly = false;
    }
  };

  updateDropdownAddressAns(
    model: QuestionEntity,
    val: any,
    isEditableAddress?: boolean
  ) {
    if (val !== "") {
      let districtCode = this.hkDistrictList.filter((x) => x.key == val);
      if (districtCode && districtCode.length > 0) {
        model.answer = districtCode[0].value;
        model.readOnly = isEditableAddress ? false : true;
        const index = this.readOnlyAddressField.indexOf(model.key);
        if (index !== -1) {
          this.readOnlyAddressField.splice(index, 1);
        }
      } else {
        //not found frm array list
      }
    } else {
      model.readOnly = false;
    }
  }

  copyMasterAddress_onChange = (
    inputKey: QuestionEntity,
    copyToKey: QuestionEntity
  ): void => {
    // Copy address from client information to risk only for IBG product
    /** Ignore copying address during endorsement for IBG */
    if (
      this.isIBGProduct &&
      !this.isGeneralEndorsement &&
      common.isDefined(this.qs_risk_sameAsAddress_checkbox)
    ) {
      const toCopy: boolean =
        this.qs_risk_sameAsAddress_checkbox.nativeElement.checked;
      if (toCopy) {
        copyToKey.answer = inputKey.answer;
      }
    }
    if (this.isIBGProduct) {
      this.updateContactInformation();
    }
  };

  clearClientCreateInformation = () => {
    this.proposal.qsClientCreate.qs_master_clientSalutation.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientSurname.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientGivenName.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientNRICPassportNo.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientDOB.clearAnswer();

    this.proposal.qsClientCreate.qs_master_clientOccupation.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientCategory.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientRace.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientNationality.clearAnswer();
    this.proposal.qsClientCreate.qs_master_clientGender.clearAnswer();

    this.proposal.qsClientCreate.qs_master_clientMaritalStatus.clearAnswer();

    this.proposal.qsClientCreate.qs_master_insuredAddress1.clearAnswer();
    this.proposal.qsClientCreate.qs_master_insuredAddress2.clearAnswer();
    this.proposal.qsClientCreate.qs_master_insuredAddress3.clearAnswer();
    this.proposal.qsClientCreate.qs_master_insuredAddress4.clearAnswer();
    this.proposal.qsClientCreate.qs_master_insuredPostalCode.clearAnswer();
    this.proposal.qsClientCreate.qs_master_insuredCity.clearAnswer();
    this.proposal.qsClientCreate.qs_master_insuredState.clearAnswer();
    this.proposal.qsClientCreate.qs_master_insuredCountry.clearAnswer();
  };

  copyInsuredAddress = (clientID?: string, clientNo?: string) => {
    const toCopy: boolean =
      this.qs_risk_sameAsAddress_checkbox.nativeElement.checked;
    // IBG product copy address from the master insured details
    if (this.isIBGProduct) {
      if (
        this.proposal.qsMaster.qs_master_insuredCountry!.answer.toLowerCase() ===
        "sg"
      ) {
        /** Ignore copying address during endorsement for IBG */
        if (toCopy && !this.isGeneralEndorsement) {
          this.proposal.qsMaster.qs_risk_insuredAddress_building!.answer =
            this.proposal.qsMaster.qs_master_insuredAddress_building!.answer;
          this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.answer =
            this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.answer;
          this.proposal.qsMaster.qs_risk_insuredAddress1!.answer =
            this.proposal.qsMaster.qs_master_insuredAddress1!.answer;
          if (this.proposal.qsMaster.qs_risk_insuredAddress1!.isAnswered()) {
            this.proposal.qsMaster.qs_risk_insuredAddress1!.readOnly = true;
          }
          this.proposal.qsMaster.qs_risk_insuredAddress2!.answer =
            this.proposal.qsMaster.qs_master_insuredAddress2!.answer;
          if (this.proposal.qsMaster.qs_risk_insuredAddress2!.isAnswered()) {
            this.proposal.qsMaster.qs_risk_insuredAddress2!.readOnly = true;
          }
          this.proposal.qsMaster.qs_risk_insuredPostalCode!.answer =
            this.proposal.qsMaster.qs_master_insuredPostalCode!.answer;
          if (this.proposal.qsMaster.qs_risk_insuredPostalCode!.isAnswered()) {
            this.proposal.qsMaster.qs_risk_insuredPostalCode!.readOnly = true;
          }
          this.proposal.qsMaster.qs_risk_insuredCity!.answer =
            this.proposal.qsMaster.qs_master_insuredCity!.answer;
          if (this.proposal.qsMaster.qs_risk_insuredCity!.isAnswered()) {
            this.proposal.qsMaster.qs_risk_insuredCity!.readOnly = true;
          }
          this.proposal.qsMaster.qs_risk_insuredState!.answer =
            this.proposal.qsMaster.qs_master_insuredState!.answer;
          if (this.proposal.qsMaster.qs_risk_insuredState!.isAnswered()) {
            this.proposal.qsMaster.qs_risk_insuredState!.readOnly = true;
          }
          this.proposal.qsMaster.qs_risk_insuredCountry!.answer =
            this.proposal.qsMaster.qs_master_insuredCountry!.answer;
        }
      } else {
        if (toCopy) {
          this.qs_risk_sameAsAddress_checkbox.nativeElement.checked = false;
          this.proposal.qsMaster.qs_risk_sameAsAddress!.answer = false;
          this.isSameAsAddressTicked = false;

          const data = {
            message: "Only Singapore address can be accepted for Risk.",
          };

          const v1DialogRef = this.cdkDialogService.open(
            MdInformationMessageComponent,
            {
              data: data,
            }
          );

          v1DialogRef.afterClosed().subscribe((result: any) => {
            // Subscription runs after the dialog closes
            if (result) {
            }
          });
        }
      }
    } else {
      // if there's customer mapping id
      if (this.proposalService.proposal.customerMappingId! > 0) {
        // copy to the policy panel
        if (toCopy && this.proposal.transType === Enum.TransType.NEW) {
          this.clientService
            .get(this.proposalService.proposal.customerMappingId!, clientNo)
            .subscribe(
              (response) => {
                if (
                  response["address"][0]["countryISO2"].toLowerCase() === "sg"
                ) {
                  this.proposal.qsMaster.qs_risk_insuredAddress_building!.answer =
                    response["address"][0]["buildingName"];
                  this.proposal.qsMaster.qs_risk_insuredAddress_lotunit!.answer =
                    response["address"][0]["unitNo"];
                  this.proposal.qsMaster.qs_risk_insuredAddress1!.answer =
                    response["address"][0]["addressLine1"];
                  this.proposal.qsMaster.qs_risk_insuredAddress1!.readOnly =
                    true;
                  this.proposal.qsMaster.qs_risk_insuredAddress2!.answer =
                    response["address"][0]["addressLine2"];
                  this.proposal.qsMaster.qs_risk_insuredAddress2!.readOnly =
                    true;
                  this.proposal.qsMaster.qs_risk_insuredPostalCode!.answer =
                    response["address"][0]["postCode"];
                  this.proposal.qsMaster.qs_risk_insuredPostalCode!.readOnly =
                    true;
                  this.proposal.qsMaster.qs_risk_insuredCity!.answer =
                    response["address"][0]["city"];
                  this.proposal.qsMaster.qs_risk_insuredCity!.readOnly = true;
                  this.proposal.qsMaster.qs_risk_insuredState!.answer =
                    response["address"][0]["stateName"];
                  this.proposal.qsMaster.qs_risk_insuredState!.readOnly = true;
                  this.proposal.qsMaster.qs_risk_insuredCountry!.answer =
                    response["address"][0]["countryISO2"];
                } else {
                  if (toCopy) {
                    this.qs_risk_sameAsAddress_checkbox.nativeElement.checked =
                      false;
                    this.proposal.qsMaster.qs_risk_sameAsAddress!.answer =
                      false;
                    this.isSameAsAddressTicked = false;
                    const data = {
                      message:
                        "Only Singapore address can be accepted for Risk.",
                    };
                    const v1DialogRef = this.cdkDialogService.open(
                      MdInformationMessageComponent,
                      {
                        data: data,
                      }
                    );

                    v1DialogRef.afterClosed().subscribe((result: any) => {
                      // Subscription runs after the dialog closes
                      if (result) {
                      }
                    });
                  }
                }
              },
              (rejection) => {
                this.notifier.error(
                  "Address is failing to retrieve.",
                  this.baseNotifierAppendTo
                );
              }
            );
        }
      }
    }
  };

  getContactInformation = () => {
    if (
      this.proposal.transType === (Enum.TransType.NEW || Enum.TransType.REN)
    ) {
      // if there's customer mapping id
      if (this.proposalService.proposal!.customerMappingId! > 0) {
        if (
          this.proposal!.proposalId!.toString() !==
          "00000000-0000-0000-0000-000000000000"
        ) {
          this.clientService
            .get(
              this.proposalService.proposal.customerMappingId!,
              this.proposalService.proposal!.qsMaster.qs_master_insuredNo!
                .answer
            )
            ?.subscribe(
              (response: any) => {
                const clientInfo = response;

                this.proposal.qsMaster.qs_master_insuredAddress_building!.answer =
                  clientInfo["address"][0]["buildingName"];
                this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.answer =
                  clientInfo["address"][0]["unitNo"];
                this.proposal.qsMaster.qs_master_insuredAddress1!.answer =
                  clientInfo["address"][0]["addressLine1"];
                this.proposal.qsMaster.qs_master_insuredAddress2!.answer =
                  clientInfo["address"][0]["addressLine2"];
                this.proposal.qsMaster.qs_master_insuredAddress3!.answer =
                  clientInfo["address"][0]["addressLine3"];
                this.proposal.qsMaster.qs_master_insuredAddress4!.answer =
                  clientInfo["address"][0]["addressLine4"];
                this.proposal.qsMaster.qs_master_insuredPostalCode!.answer =
                  clientInfo["address"][0]["postCode"];
                this.proposal.qsMaster.qs_master_insuredCity!.answer =
                  clientInfo["address"][0]["city"];
                this.proposal.qsMaster.qs_master_insuredState!.answer =
                  clientInfo["address"][0]["stateName"];
                this.proposal.qsMaster.qs_master_insuredCountry!.answer =
                  clientInfo["address"][0]["countryISO2"];
              },
              (rejection: any) => {
                this.notifier.error(
                  "Contact Information is failing to retrieve.",
                  this.baseNotifierAppendTo
                );
              }
            );
        }
      }
    }
  };

  updateContactInformation = () => {
    let contactInfo = "";
    if (this.proposal.qsMaster.qs_master_insuredCountry!.answer === "SG") {
      contactInfo =
        this.proposal.qsMaster.qs_master_insuredAddress1!.answer +
        " " +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress2!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress3!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress4!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress_building!.answer
        ) +
        this.addressCountryIfUnknown(
          this.proposal.qsMaster.qs_master_insuredCountry!.answer
        ) +
        " " +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredPostalCode!.answer
        );
    } else {
      contactInfo =
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress_building!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress1!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress2!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress3!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredAddress4!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredPostalCode!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredCity!.answer
        ) +
        this.getAddressFormat(
          this.proposal.qsMaster.qs_master_insuredState!.answer
        ) +
        this.addressCountryIfUnknown(
          this.proposal.qsMaster.qs_master_insuredCountry!.answer
        );
    }

    this.contactInformation = contactInfo;
    this.proposal.qsMaster.qs_master_preferredDeliveryMethod_CorrAddress!.answer =
      contactInfo;
  };

  getAddressFormat = (input: any) => {
    if (!common.isUndefinedOrNull(input)) {
      return input + " ";
    } else {
      return "";
    }
  };

  getAddressDefined = (input: any) => {
    if (!common.isUndefinedOrNull(input)) {
      return true;
    } else {
      return false;
    }
  };

  addressCountryIfUnknown = (inputId: string) => {
    let displayText: string = "Unknown";
    if (inputId.length > 0) {
      this.countryList.forEach((i) => {
        if (i.value === inputId) {
          displayText = i.key;
        }
      });
    }

    return displayText;
  };

  showChangeOfCorrespondenceAddressModal = (command: number) => {
    const data: InterfaceProduct.IDialogMessage = {
      message: "Change of Correspondence Address",
      tabClicked: command,
    };
    //disabled in old ui
    // this._dialog = this.dialog.modalLarge(
    //     'shared/partials/mdChangeOfCorrespondenceAddress/changeOfCorrespondenceAddress.html',
    //     'ChangeOfCorrespondenceAddressController', data
    //     , this.confirmationModalCloseForChangeOfCorrespondenceAddress, undefined, undefined, undefined, false, 'standardModal');
  };

  GotoUpdateClientInfo() {
    this.FindExistingPoliciesForClient(null);
  }

  UpdateClientInfo = (response: any) => {
    // DBSEP-2063: add this block to popup the confirmation box with list of policy and with button of update and new client
    // Update button of confirmation box will allow to update the client  and newclient button will open new popup of new client screen
    if (response.status == "yes") {
      this.updateClientData();
      let command = response.data;
      //if (!this.isChecker && !this.isMaker)
      {
        this.FindExistingPoliciesForClient(command);
      }
      // else {
      //   this.GoToClientPage(false);
      // }
    }
  };

  mapClientdata(sourcedata: ClientEntity, destinationdata: ClientEntity) {
    if (Array.isArray(sourcedata.address)) {
      //need deep-copy, which copy-by-value, not copy-by-reference
      destinationdata.address = JSON.parse(JSON.stringify(sourcedata.address));
    } else {
      destinationdata.address = sourcedata.address;
    }

    destinationdata.clientId = sourcedata.clientId;
    destinationdata.clientCode = sourcedata.clientCode;
    destinationdata.clientType = sourcedata.clientType;
    destinationdata.nric = sourcedata.nric;
    destinationdata.passportNo = sourcedata.passportNo;
    destinationdata.businessRegistrationNumber =
      sourcedata.businessRegistrationNumber;
    destinationdata.dbsClientReferenceNumber =
      sourcedata.dbsClientReferenceNumber;
    destinationdata.incorporatedDate = sourcedata.incorporatedDate;
    destinationdata.personInCharge = sourcedata.personInCharge;
    destinationdata.firstName = sourcedata.firstName;
    destinationdata.lastName = sourcedata.lastName;
    destinationdata.clientName = sourcedata.clientName;
    destinationdata.salutation = sourcedata.salutation;
    destinationdata.gender = sourcedata.gender;
    destinationdata.birthDate = sourcedata.birthDate;
    destinationdata.occupation = sourcedata.occupation;
    destinationdata.nationality = sourcedata.nationality;
    destinationdata.race = sourcedata.race;
    destinationdata.maritalStatus = sourcedata.maritalStatus;
    destinationdata.phoneNoHome = sourcedata.phoneNoHome;
    destinationdata.phoneNoOffice = sourcedata.phoneNoOffice;
    destinationdata.mobileNumber = sourcedata.mobileNumber;
    destinationdata.pagerNo = sourcedata.pagerNo;
    destinationdata.faxNumber = sourcedata.faxNumber;
    destinationdata.emailAddress = sourcedata.emailAddress;
    destinationdata.docType = sourcedata.docType;
    destinationdata.cinNo = sourcedata.cinNo;
  }

  updateClientData() {
    if (this.proposalService.proposal.customerMappingId != null) {
      this.clientService
        .get(
          this.proposalService.proposal.customerMappingId,
          this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
        )
        .subscribe((response) => {
          if (
            common.isDefined(
              this.clientService.clientDictionary[
                this.proposalService.proposal.customerMappingId!
              ]
            )
          ) {
            this.clientDictionary = new ClientEntity();
            this.mapClientdata(
              this.clientService.clientDictionary[
                this.proposalService.proposal.customerMappingId!
              ],
              this.clientDictionary
            );
          }
        });
    }
  }

  // DBSEP-2063: It will check the confirmation from user and redirect to client edit or create page.
  PolicyconfirmationModalCloseForActions = (response: any): void => {
    if (response.status === "yes") {
      let data = response.data;
      if (this.proposalService.proposal.customerMappingId != null) {
        this.updateClientData();
        this.GoToClientPage(false);
      }
    } else {
      this.GoToClientPage(true);
    }
  };

  // DBSEP-2063:  It will check the mapping of policy with clientId. If policy exists then a new popup will be open otherwise
  //it will directally go to client update page.
  FindExistingPoliciesForClient = (command: any) => {
    this.clientService
      .FindExistingPoliciesForClient(
        this.proposalService.proposal.customerMappingId!,
        this.proposalService.proposal.qsMaster.qs_master_insuredNo!.answer
      )
      .subscribe(
        (response: any) => {
          let data: InterfaceProduct.IDialogMessage = {
            message: response?.body?.message,
            tabClicked: command,
          };
          if (response?.body?.message == "") {
            this.GoToClientPage(false);
          } else {
            this.showMdConfirmationMessageV5(data);
          }
        },
        (rejection: any) => {
          this.notifier.error("Failed to save.", this.baseNotifierAppendTo);
          dialogActionNotifier.cancel_notifier$.next({
            key: "client-save-close",
            value: { status: "failed" },
          });
        }
      );
  };

  private showMdConfirmationMessageV5(data: InterfaceProduct.IDialogMessage) {
    let v1InputData: v5DlgInputArgs = {
      opened: true,
      dialogWidth: 600,
      data: data,
      message: data.message,
    };

    const v1DialogRef = this.cdkDialogService.open(
      MdConfirmationMessageV5Component,
      {
        data: v1InputData,
      }
    );

    v1DialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result.status == "yes") {
        this.PolicyconfirmationModalCloseForActions(result);
      }
    });
  }

  // DBSEP-2063:  It will transfer on client create or client edit page as per the flag.
  GoToClientPage(data: any) {
    const postData: clientCreateDlgInputArgs = {
      isNewClient: data,
      proposal: this.proposal,
    };
    const clientCreateDialogRef = this.cdkDialogService.open(
      MfHkClientCreateComponent,
      {
        data: postData,
      }
    );
    clientCreateDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
        this.confirmationModalCloseForActions(result);
      }
    });
  }

  confirmationModalCloseForChangeOfClientInfoController = (data: any): void => {
    if (Global.isDefined(data.clientData)) {
      this.proposalService.proposal.qsMaster.qs_master_mobileNumber!.answer =
        data.clientData.mobileNumber;
      this.proposalService.proposal.qsMaster.qs_master_emailID!.answer =
        data.clientData.emailAddress;

      this.proposalService.proposal.qsMaster.qs_master_salutation!.answer =
        data.clientData.salutation;
      // if (data.clientData.clientType === 1) {
      //   this.proposalService.proposal.qsMaster.qs_master_insuredName!.answer =
      //     data.clientData.lastName + " " + data.clientData.firstName;
      //   this.proposal.qsMaster.qs_master_clientName!.answer =
      //     data.clientData.lastName + "-" + data.clientData.firstName;
      // } else
      {
        this.proposalService.proposal.qsMaster.qs_master_insuredName!.answer =
          data.clientData.clientName;
        this.proposal.qsMaster.qs_master_clientName!.answer =
          data.clientData.clientName;
      }

      this.proposalService.proposal.qsMaster.qs_master_passport!.answer =
        data.clientData.passportNo;
      this.proposalService.proposal.qsMaster.qs_master_nric!.answer =
        data.clientData.nric;
      this.proposalService.proposal.qsMaster.qs_master_occupation!.answer =
        data.clientData.occupation;
      this.proposalService.proposal.qsMaster.qs_master_birthDate!.answer =
        data.clientData.birthDate;
      this.proposalService.proposal.qsMaster.qs_master_race!.answer =
        data.clientData.race;
      this.proposalService.proposal.qsMaster.qs_master_nationality!.answer =
        data.clientData.nationality;
      this.proposalService.proposal.qsMaster.qs_master_gender!.answer =
        data.clientData.gender;
      this.proposalService.proposal.qsMaster.qs_master_maritalStatus!.answer =
        data.clientData.maritalStatus;
      this.proposalService.proposal.qsMaster.qs_master_phoneNoHome!.answer =
        data.clientData.phoneNoHome;
      this.proposalService.proposal.qsMaster.qs_master_phoneNoOffice!.answer =
        data.clientData.phoneNoOffice;
      this.proposalService.proposal.qsMaster.qs_master_faxNumber!.answer =
        data.clientData.faxNumber;
      this.proposalService.proposal.qsMaster.qs_master_cin!.answer =
        data.clientData.cinNo;
      this.proposalService.proposal.qsMaster.qs_master_insuredNoDocType!.answer =
        data.clientData.docType;
    }
  };

  confirmationModalCloseForChangeOfCorrespondenceAddress = (
    data: any
  ): void => {
    if (Global.isDefined(data.clientAddressData)) {
      this.proposal.qsMaster.qs_master_insuredAddress1!.answer =
        data.clientAddressData.addressLine1;
      this.proposal.qsMaster.qs_master_insuredAddress2!.answer =
        data.clientAddressData.addressLine2;
      this.proposal.qsMaster.qs_master_insuredAddress3!.answer =
        data.clientAddressData.addressLine3;
      this.proposal.qsMaster.qs_master_insuredAddress4!.answer =
        data.clientAddressData.addressLine4;
      this.proposal.qsMaster.qs_master_insuredAddress_building!.answer =
        data.clientAddressData.buildingName;
      this.proposal.qsMaster.qs_master_insuredCity!.answer =
        data.clientAddressData.city;
      this.proposal.qsMaster.qs_master_insuredState!.answer =
        data.clientAddressData.stateName;
      this.proposal.qsMaster.qs_master_insuredCountry!.answer =
        data.clientAddressData.countryISO2;
      this.proposal.qsMaster.qs_master_insuredAddress_lotunit!.answer =
        data.clientAddressData.unitNo;
      this.proposal.qsMaster.qs_master_insuredPostalCode!.answer =
        data.clientAddressData.postCode;
    }
  };

  openAddressHelp(event: { preventDefault: () => void }): void {
    event.preventDefault();
    const data = {
      // 'proposalModel': this.proposal.proposalModel,
      showGeoCode: false, // determine to hide/show geocode
    };

    const clientSearchDialogRef = this.cdkDialogService.open(
      MfHkAddressHelpFormComponent,
      {
        data: data,
      }
    );

    clientSearchDialogRef.afterClosed().subscribe((result: any) => {
      // Subscription runs after the dialog closes
      if (result) {
      }
    });
  }

  qs_master_qs_fire_building = (data: any): void => {
    switch (this.proposal.productType) {
      case EnumHK.MortgageFireHKProduct.HSCMB:
      case EnumHK.MortgageFireHKProduct.HSWPB:
        // case EnumSG.MortgageFireSgProduct.HDB:
        // case EnumSG.MortgageFireSgProduct.PTE:
        // case EnumSG.MortgageFireSgProduct.MIP:
        if (this.proposal.qsMaster.qs_fire_building!.isAnswered()) {
          var firebuilding = this.proposal.qsMaster.qs_fire_building!.answer;
          var firereplacement =
            this.proposal.qsMaster.qs_fire_replacement!.answer;
          if (firebuilding.indexOf("$") > -1) {
            this.proposal.qsMaster.qs_fire_building!.answer =
              firebuilding.substring(1);
          }

          if (firereplacement.indexOf("$") > -1) {
            this.proposal.qsMaster.qs_fire_replacement!.answer =
              firereplacement.substring(1);
          }
          const index = Math.round(
            this.proposal.qsMaster.qs_fire_replacement!.answer
          );
          const answer = Math.round(
            this.proposal.qsMaster.qs_fire_building!.readAnswerAsNumeric()!
          );
          if (answer === index) {
            this.isBuildingSIModified = false;
          } else {
            this.isBuildingSIModified = true;
          }
        }
        break;
      default:
      // do nothing
    }
  };

  qs_master_lst_onChange = (data: any): void => {
    if (data === "1") {
      // yes
      if (
        !Global.isDefined(
          this.proposal.qsMaster.qs_master_lstDetails!.answer
        ) ||
        this.proposal.qsMaster
          .qs_master_lstDetails!.answer.toString()
          .trim() === ""
      ) {
        this.lstDefaultValue = cloneDeep(this.lstDetailsTemplate);
        this.proposal.qsMaster.qs_master_lstDetails!.answer = cloneDeep(
          this.lstDetailsTemplate
        );
      } else {
        if (
          typeof this.proposal.qsMaster.qs_master_lstDetails!.answer ===
          "string"
        ) {
          this.lstDefaultValue = cloneDeep(
            JSON.parse(this.proposal.qsMaster.qs_master_lstDetails!.answer)
          );
        } else {
          // skip the parsing
          this.lstDefaultValue = cloneDeep(
            this.proposal.qsMaster.qs_master_lstDetails!.answer
          );
        }
      }
      /// DBSEP - 2050 : Automation Of LST
      if (this.isCancel && this.isIncomplete && !this.isAutoLst) {
        this.proposal.qsMaster.qs_master_lstDetails!.readOnly = false;
      }
    } else if (data === "2") {
      // no
      this.proposal.qsMaster.qs_master_lstDetails!.clearAnswer();
      this.lstDefaultValue = "";
    }
  };

  qs_master_preferredDeliveryMethodChange = (data: any): void => {
    this.proposalService.fieldValidationManager.setEmailMobileRequires(
      data,
      this.isIBGProduct
    );
    this.qs_master_preferredDeliveryText(
      this.proposal.qsMaster.qs_master_preferredDeliveryMethod!
    );
  };

  qs_fire_freeInsuranceFirstYear_onChange = (data: any): void => {
    this.proposalService.fieldValidationManager.setNoOfFreeInsuranceYearsRequires();
  };

  toShowQuestion(question: any) {
    let flag: boolean = false;
    if (this.proposal.proposalStatus != Enum.ProposalStatus.Incomplete) {
      switch (question) {
        case "qs_master_preferredDeliveryMethod_Email":
          flag =
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!
              .answer != undefined
              ? true
              : false;
          flag =
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!.readOnly =
              true;
          break;
        case "qs_master_preferredDeliveryMethod_MobileNo":
          flag =
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!
              .answer != undefined
              ? true
              : false;
          this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.readOnly =
            true;
          break;
        case "qs_master_preferredDeliveryMethod":
          flag =
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.answer !=
              undefined &&
            !this.proposal.qsMaster.qs_master_preferredDeliveryMethod!.readOnly
              ? false
              : true;
          break;
        case "qs_master_preferredDeliveryMethod_CorrAddress":
          flag =
            this.proposal.qsMaster
              .qs_master_preferredDeliveryMethod_CorrAddress!.answer !=
            undefined
              ? false
              : true;
          break;
      }
    }
    if (this.proposal.proposalStatus === Enum.ProposalStatus.Incomplete) {
      switch (question) {
        case "qs_master_preferredDeliveryMethod_Email":
          flag = true;
          if (this.isGeneralEndorsement)
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod_Email!.readOnly =
              false;
          break;
        case "qs_master_preferredDeliveryMethod_MobileNo":
          flag = true;
          if (this.isGeneralEndorsement)
            this.proposal.qsMaster.qs_master_preferredDeliveryMethod_MobileNo!.readOnly =
              false;
          break;
        case "qs_master_preferredDeliveryMethod":
          if (
            this.isCancel ||
            (this.isEndorsement &&
              this.proposal.endorsementType ===
                Enum.EndorsementType.LoanRedemption)
          ) {
            flag = true;
          }
      }
    }
    return flag;
  }

  qs_master_refundDisplayText = (question: QuestionEntity) => {
    switch (question.answer) {
      case "1":
        this.refundDisplayText = "Default";
        break;

      case "2":
        this.refundDisplayText = "Bank";
        break;
      case "3":
        this.refundDisplayText = "Policy Holder";
        break;
      case "4":
        this.refundDisplayText = "No Refund";
        break;
      default:
        this.refundDisplayText = "Default";
        break;
    }
  };

  qs_master_preferredDeliveryText = (question: QuestionEntity) => {
    switch (question.answer) {
      case "2":
        this.preferredDeliveryText = "Email";
        break;

      case "5":
        this.preferredDeliveryText = "SMS";
        break;
      case "1":
        this.preferredDeliveryText = "No Mobile/Email or Opt Out";
        break;

      default:
        this.preferredDeliveryText = "";
        break;
    }
  };

  updateLSTDetails = (
    evt?: any,
    rowNumber?: string,
    param?: any,
    id?: string,
    decimals?: number
  ) => {
    // update the value to correct decimals
    if (rowNumber && Global.isDefined(rowNumber) && Global.isDefined(param)) {
      let val: any = "";
      let convertDecimals: string = "";
      let currentVal: number;
      if (
        navigator.userAgent.search("Firefox") <= -1 &&
        evt !== undefined &&
        evt &&
        evt.currentTarget
      ) {
        let ct = evt.currentTarget as HTMLInputElement;
        currentVal = parseFloat(ct.value);
        convertDecimals = currentVal.toFixed(decimals);
      } else {
        currentVal = parseFloat(this.getNumericTextboxValue(id));
        convertDecimals = currentVal.toFixed(decimals);
      }
      val = param.includes("amount")
        ? convertDecimals
        : parseFloat(convertDecimals) >= 0
        ? convertDecimals
        : 0;
      this.lstDefaultValue[rowNumber][param] = val;
    }
    this.proposal.qsMaster.qs_master_lstDetails!.answer = JSON.stringify(
      this.lstDefaultValue
    );
  };

  getNumericTextboxValue(id: any) {
    switch (id) {
      case "lst_0_percent1":
        if (common.isDefined(this.lst_0_percent1)) {
          return this.lst_0_percent1.nativeElement.value;
        }
        break;
      case "lst_0_amount1":
        if (common.isDefined(this.lst_0_amount1)) {
          return this.lst_0_amount1.nativeElement.value;
        }
        break;
      case "lst_0_percent2":
        if (common.isDefined(this.lst_0_amount2)) {
          return this.lst_0_amount2.nativeElement.value;
        }
        break;
      case "lst_1_percent1":
        if (common.isDefined(this.lst_1_percent1)) {
          return this.lst_1_percent1.nativeElement.value;
        }
        break;
      case "lst_1_amount1":
        if (common.isDefined(this.lst_1_amount1)) {
          return this.lst_1_amount1.nativeElement.value;
        }
        break;
      case "lst_1_amount1":
        if (common.isDefined(this.lst_1_amount1)) {
          return this.lst_1_amount1.nativeElement.value;
        }
        break;
      case "lst_1_percent2":
        if (common.isDefined(this.lst_1_percent2)) {
          return this.lst_1_percent2.nativeElement.value;
        }
        break;
      case "lst_1_amount2":
        if (common.isDefined(this.lst_1_amount2)) {
          return this.lst_1_amount2.nativeElement.value;
        }
        break;
      case "lst_2_percent1":
        if (common.isDefined(this.lst_2_percent1)) {
          return this.lst_2_percent1.nativeElement.value;
        }
        break;
      case "lst_2_amount1":
        if (common.isDefined(this.lst_2_amount1)) {
          return this.lst_2_amount1.nativeElement.value;
        }
        break;
      case "lst_2_percent2":
        if (common.isDefined(this.lst_2_percent2)) {
          return this.lst_2_percent2.nativeElement.value;
        }
        break;
      case "lst_2_amount2":
        if (common.isDefined(this.lst_2_amount2)) {
          return this.lst_2_amount2.nativeElement.value;
        }
        break;
      case "lst_3_percent1":
        if (common.isDefined(this.lst_3_percent1)) {
          return this.lst_3_percent1.nativeElement.value;
        }
        break;
      case "lst_3_amount1":
        if (common.isDefined(this.lst_3_amount1)) {
          return this.lst_3_amount1.nativeElement.value;
        }
        break;
      case "lst_0_percent2":
        if (common.isDefined(this.lst_0_percent2)) {
          return this.lst_0_percent2.nativeElement.value;
        }
        break;
      case "lst_3_amount2":
        if (common.isDefined(this.lst_3_amount2)) {
          return this.lst_3_amount2.nativeElement.value;
        }
        break;
      default:
        return "0";
    }
  }

  //2022-08-24 dagoh0: APACDM-363 - set expiry date based on selected plan type
  qs_master_planType_onChange = (data: any): void => {
    this.proposal.qsMaster.qs_master_poi_endDate!.answer = this.setExpiryDate();
  };

  private setExpiryDate = () => {
    let annualExpiryDate = cloneDeep(
      this.proposal.qsMaster.qs_master_poi_startDate!.answer
    );
    const planType =
      this.proposal.qsMaster.qs_master_planType != null &&
      this.proposal.qsMaster.qs_master_planType.answer != null
        ? +this.proposal.qsMaster.qs_master_planType!.answer
        : 0;

    if (planType > 0) {
      annualExpiryDate.setYear(annualExpiryDate.getFullYear() + planType);
      annualExpiryDate.setDate(annualExpiryDate.getDate() - 1);
    } else {
      annualExpiryDate.setYear(annualExpiryDate.getFullYear() + 1);
      annualExpiryDate.setDate(annualExpiryDate.getDate() - 1);
    }
    return annualExpiryDate;
  };
  //#endregion
}
